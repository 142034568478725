<mat-card  [fxFlex.lt-md]="200" fxLayout="column" fxLayoutAlign="center center" >
    <form [formGroup]="form">
        <h4>{{'Detalle los cambios a realizar' | translate}}</h4>
            <br>
        <textarea id="texto" class="form-control" formControlName="texto"></textarea>
            <br>
            <div class="botones">
                <button mat-stroked-button class="enviar" id="enviar" (click)="consultar()">{{'ENVIAR' | translate}}</button>
                <button class="mat-raised-button cerrar"(click)="close()">{{'CERRAR' | translate}}</button>
            </div>
         </form>
</mat-card>
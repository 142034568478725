import { Component, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { environment } from '../../../environments/environment';
// import { TranslationComponent } from '../../translation/translation.component';
// import { load } from '@angular/core/src/render3';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  idCliente: any;
  tipoCliente: string;
  margenizquierda: any;
  margenderecha: any;
  MostrarConsumosPorHora: any;
  MostrarConsumosPorHoraClientes: any;
  idiomas: boolean;
  IsAdministrador:boolean;
  NombreCliente:string;
  tokenvalidado: any;

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _funcionesService: FuncionesService
  ) {
      this._loginService.token = localStorage.getItem('token');
      this.tipoCliente = localStorage.getItem('TipoCliente');

      switch (environment.alinearmenusuperior) {
        case 'derecha': {
          this.margenizquierda = 'fill-space';
          this.margenderecha = '';
          break;
        }
        case 'izquierda': {
          this.margenderecha = 'fill-space';
          this.margenizquierda = '';
          break;
      }
      case 'centro': {
        this.margenderecha = 'fill-space';
        this.margenizquierda = 'fill-space';
        break;
    }
  }
}

  ngOnInit() {
    this.MostrarConsumosPorHora = environment.MostrarConsumosPorHora;
    this.MostrarConsumosPorHoraClientes = environment.MostrarConsumosPorHoraClientes;
    this.idiomas = environment.idiomas;
    if (this._loginService.datosTitular === undefined) {
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._loginService.getLogin(this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('u'))),
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('p'))))
        .toPromise()
        .then(async data => {

          this._loginService.datosTitular = this._funcionesService.decodificarToken(data);
          // console.log(this._loginService.datosTitular);
          this.loadData();
        })}else{
          this.router.navigate(['/salir']);
        }});
    } else {
      this.loadData();
    }
  }

  loadData() {
        this.IsAdministrador = (localStorage.getItem('isAdminFincas') === 'true');
        if (this.IsAdministrador) {
          this.NombreCliente = this._loginService.datosTitular.NombreAdministrador;
      } else {
        this.NombreCliente = this._loginService.datosTitular.NombreCliente;
      }
  }

  //Funcionalidad para cerrar la ventana (Han solicitado un botón que lo haga, no es broma)
  cerrar(){
      if (confirm("Close Window?")) {
        window.close();
       }
   }
}

<app-header></app-header>



<div class="wrapper">

    <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxLayoutAlign="center stretch" >
        <button class="cerrar" mat-button (click)="cerrar()" fxLayoutGap="20px">
          <i class="fas fa-times"></i>
          <span class="botontexto">{{'CERRAR PESTAÑA'  | translate}}</span>
        </button> 
      </mat-card>
    </div>
  </div>
  

  
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <!-- Datos de titular -->
    <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
      <mat-card-header>
        <mat-card-title>{{'DATOS DEL CLIENTE' | translate}}</mat-card-title>
        <!-- <mat-card-subtitle>{{'Muestra los datos principales del cliente.' | translate}}</mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>

        <!-- Documento / Razón social / Nombre Apellido1 Apellido2 -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex>
            <input *ngIf="!UnirPerfilDatosEnvioYContacto" matInput placeholder="{{'Razon Social' | translate}}" value="{{NombreCliente}}" disabled="PerfilIdentidadNombreClienteDisabled">
            <input *ngIf="UnirPerfilDatosEnvioYContacto" matInput placeholder="{{'Titular' | translate}}" value="{{NombreCliente}}" disabled="PerfilIdentidadNombreClienteDisabled">
            <span class="fal fa-building" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{'DNI/CIF/NIE'| translate}}" value="{{IdentidadCliente}}" disabled="PerfilIdentidadIdentidadClienteDisabled">
            <span class="fal fa-file-alt" matSuffix></span>
          </mat-form-field>
        </div>
        
        <!-- Código Postal / Provincia / Ciudad -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex="35">
            <input matInput placeholder="{{'Población' | translate}}" value="{{CiudadCliente}}" disabled="PerfilIdentidadCiudadClienteDisabled">
            <span class="fal fa-city" matSuffix></span>
          </mat-form-field>
       <mat-form-field fxFlex="25">
            <input matInput placeholder="{{'Provincia' | translate}}" value="{{ProvinciaCliente}}" disabled="PerfilIdentidadProvinciaClienteDisabled">
            <span class="fal fa-map-marker-alt" matSuffix></span>
          </mat-form-field>
           <mat-form-field fxFlex="10">
            <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostalCliente}}" disabled="PerfilIdentidadCodigoPostalClienteDisabled">
            <span class="fal fa-map-pin" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <input matInput placeholder="{{'Descripción del suministro' | translate}}" value="{{DatosEnvio?.AclaradorEnvio}}" disabled="PerfilIdentidadDireccionClienteDisabled">
            <span class="fal fa-address-book" matSuffix></span>
          </mat-form-field>
         
         </div>
      </mat-card-content>
     </mat-card>
  </div>

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="MostrarDatosContratoEnPerfil">
    <!-- Datos de CONTRATO -->
    <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
      <mat-card-header>
        <mat-card-title>{{'DATOS TÉCNICOS' | translate}}</mat-card-title>
        <!-- <mat-card-subtitle>{{'Muestra los datos principales del contrato.' | translate}}</mat-card-subtitle> 
          Han pedido ocultarlo, lmao
        -->
      </mat-card-header>
      <mat-card-content>

      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <!-- Código Contrato -->
        <mat-form-field fxFlex="25">
          <input matInput placeholder="{{'Código Contrato' | translate}}" value=" {{CodigoContrato}}"
            disabled="true">
          <span class="fal fa-barcode" matSuffix></span>
        </mat-form-field>
        <!-- Código CUPS -->
        <mat-form-field fxFlex="50" class="example-full-width">
          <input matInput placeholder="{{'Código CUPS' | translate}}" value="{{Cups}}" disabled="true">
            <span class="fal fa-tachometer-alt" matSuffix></span>
        </mat-form-field>
        <!-- Tarifa -->
        <mat-form-field fxFlex="25">
          <input matInput placeholder="{{'Tarifa' | translate}}" value=" {{Tarifa}}"
            disabled="true">
          <span class="fal fa-chart-bar" matSuffix></span>
        </mat-form-field>
      </div>

      <mat-card-header>
        <mat-card-title>{{'Potencias contratadas (KW):' | translate}}</mat-card-title>
       </mat-card-header>

     <div fxLayout="row" fxLayout.lt-lg="column" class="m-20"  fxLayoutGap="20px">
      <div fxFlex *ngFor="let potencia of Potencias; let i = index">
         <!-- <mat-form-field> -->
            <mat-form-field  *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
            && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)" fxFlex="16">
            <!-- <input matInput placeholder="{{potencia.TextoPotencia}}" value="{{potencia.PotenciaContratada}}"
                disabled> -->
                <!-- <div>
                  <span>{{'Potencia Contratada' | translate}}</span>
                  <span class="fal fa-tachometer-alt-average" matSuffix></span>
                </div> -->
                 <input matInput placeholder="{{potencia.TextoPotencia}}" value="{{potencia?.PotenciaContratada.slice(0,-2)}}" disabled>
                 
              </mat-form-field>
              
            <!-- TODO: Pediente de desarrrollo; mostrar precio de potencia y de energia que se está facturando al suministro ¿NANI? -->
            <!-- <div><i class="far fa-level-up fa-flip-horizontal"></i> {{potencia.PotenciaContratada*5}} €</div> -->
        </div>
       </div>
        
       
      </mat-card-content>
      </mat-card>
  </div>

  <div fxLayoutAlign="center stretch" >
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px" class="banck">
      <!-- Método de pago -->
      <mat-card fxFlex>
        <mat-card-header>
          <mat-card-title>{{'DATOS BANCARIOS' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <!-- <mat-list-item >
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Nombre' | translate}}" value="{{MetodosPago?.NombrePagador}}" disabled="PerfilIdentidadNombrePagador">
                <span class="fal fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MostrarPerfilContratoColectivo">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Colectivo' | translate}}" value="{{MetodosPago?.ColectivoPagador}}" disabled="PerfilIdentidadColectivoPagador">
                <span class="fal fa-users" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'CIF' | translate}}" value="{{MetodosPago?.IdentificadorPagador}}" disabled="PerfilIdentidadIdentificadorPagador">
                <span class="fal fa-id-card" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Tipo Cobro' | translate}}" value="{{MetodosPago?.TipoCobroPagador}}" disabled="PerfilIdentidadTipoCobroPagador">
                <span class="fal fa-credit-card" matSuffix></span>
              </mat-form-field>
            </mat-list-item> 

                           *** HAN SOLICITADO OCULTAR TODOS ESTOS CAMPOS ***
          
          -->
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="IBAN" value="{{MetodosPago?.IBANPagador}}" disabled="PerfilIdentidadIBANPagador">
                <span class="fal fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Banco' | translate}}" value="{{MetodosPago?.BancoPagador}}" disabled="PerfilIdentidadBancoPagador">
                <span class="fal fa-university" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <!-- <mat-card-actions fxLayout="row" fxLayoutAlign="end end"
        *ngIf="!PerfilIdentidadNombrePagador || !PerfilIdentidadColectivoPagador || !PerfilIdentidadIdentificadorPagador ||
                !PerfilIdentidadTipoCobroPagador ||
                !PerfilIdentidadIBANPagador ||
                !PerfilIdentidadBancoPagador">
          <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions> -->
      </mat-card>
      
      <!-- Datos de Contacto -->
      <mat-card fxFlex *ngIf="!UnirPerfilDatosEnvioYContacto">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE CONTACTO' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Nombre Contacto'| translate}}" value="{{DatosContacto?.NombreContacto}}" disabled="PerfilIdentidadNombreContacto">
                <span class="fal fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Tipo Contacto' | translate}}" value="{{DatosContacto?.TipoContacto}}"
                  disabled="PerfilIdentidadTipoContacto">
                <span class="fal fa-users" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Valor' | translate}}" value="{{DatosContacto?.ValorContacto}}" disabled="PerfilIdentidadValorContacto">
                <span class="fal fa-envelope" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <!-- <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilIdentidadNombreContacto || !PerfilIdentidadTipoContacto || !PerfilIdentidadValorContacto">
          <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions> -->
      </mat-card>
      <!-- Datos de Envío -->
      <mat-card fxFlex *ngIf="!UnirPerfilDatosEnvioYContacto">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE ENVÍO' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Datos' | translate}}" value="{{DatosEnvio?.NombreEnvio}}" disabled="PerfilIdentidadNombreEnvio">
                <span class="fal fa-fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item> 
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Población' | translate}}" value="{{DatosEnvio?.PoblacionEnvio}}" disabled="PerfilIdentidadPoblacionEnvio">
                <span class="fal fa-city" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostalCliente}}" disabled="PerfilIdentidadCodigoPostalClienteDisabled">
              <span class="fal fa-map-pin" matSuffix></span>
            </mat-form-field>
             <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Dirección' | translate}}" value="{{DatosEnvio?.DireccionEnvio}}" disabled="PerfilIdentidadDireccionEnvio">
                <span class="fal fa-address-book" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Número' | translate}}" value="{{DatosEnvio?.NumeroEnvio}}" disabled="PerfilIdentidadNumeroEnvio">
                <span class="fal fa-list-ol" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Descripción del suministro' | translate}}" value="{{DatosEnvio?.AclaradorEnvio}}" disabled="PerfilIdentidadAclaradorEnvio">
                <span class="fal fa-sign" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <!-- <mat-list-item>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Código Postal' | translate}}" value="{{DatosEnvio?.CodigoPostalEnvio}}" disabled="PerfilIdentidadCodigoPostalEnvio">
                <span class="fal fa-map-pin" matSuffix></span>
              </mat-form-field>
            </mat-list-item> -->
          </mat-list>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilIdentidadNombreEnvio ||
            !PerfilIdentidadPoblacionEnvio ||
            !PerfilIdentidadCodigoPostalEnvio ||
            !PerfilIdentidadDireccionEnvio ||
            !PerfilIdentidadNumeroEnvio ||
            !PerfilIdentidadAclaradorEnvio">
          <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card>




<!-- DATOS DE CONTACTO Y ENVÍO DEL PUNTO DE SUMINISTRO -->
<mat-card *ngIf="UnirPerfilDatosEnvioYContacto"  class="banck" fxFlex="50" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
  <mat-card-header>
    <mat-card-title>{{'DATOS DEL SUMINISTRO' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- <div fxFlex>
    <mat-list role="list" fxFlex>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Nombre Contacto'| translate}}" value="{{DatosContacto?.NombreContacto}}" disabled="PerfilIdentidadNombreContacto">
          <span class="fal fa-file-user" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Tipo Contacto' | translate}}" value="{{DatosContacto?.TipoContacto}}"
            disabled="PerfilIdentidadTipoContacto">
          <span class="fal fa-users" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Valor' | translate}}" value="{{DatosContacto?.ValorContacto}}" disabled="PerfilIdentidadValorContacto">
          <span class="fal fa-envelope" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
    </mat-list>
 </div>

          *** HAN PEDIDO OCULTAR ESTOS DATOS ***
 -->

<!-- Datos de Envío -->
  <div fxFlex>
    <mat-list role="list" fxFlex>
      <!-- <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Dirección' | translate}}" value="{{DatosEnvio?.NombreEnvio}}" disabled="PerfilIdentidadNombreEnvio">
          <span class="fal fa-location" matSuffix></span>
        </mat-form-field>
      </mat-list-item> -->
      <mat-list-item>
        <mat-form-field fxFlex  class="example-full-width">
          <input matInput placeholder="{{'Dirección' | translate}}" value="{{Direccion}}" disabled="PerfilIdentidadDireccionEnvio">
          <span class="fal fa-address-book" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Número' | translate}}" value="{{Numero}}" disabled="PerfilIdentidadNumeroEnvio">
          <span class="fal fa-list-ol" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item> 
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Población' | translate}}" value="{{Ciudad}}" disabled="PerfilIdentidadPoblacionEnvio">
          <span class="fal fa-city" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Provincia' | translate}}" value="{{ProvinciaCliente}}" disabled="PerfilIdentidadPoblacionEnvio">
          <span class="fas fa-map-marked-alt" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostal}}" disabled="PerfilIdentidadCodigoPostalEnvio">
          <span class="fal fa-map-pin" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Descripción del suministro' | translate}}" value="{{Aclarador}}" disabled="PerfilIdentidadAclaradorEnvio">
          <span class="fal fa-sign" matSuffix></span>
        </mat-form-field>
      </mat-list-item>
    </mat-list>
  </div>
  </mat-card-content>
</mat-card>

</div>
</div>


<app-footer></app-footer>

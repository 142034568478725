<mat-toolbar color="primary" class="mat-elevation-z6">

  <img class="logoHeader" src="assets/img/logo.png" id="logoEmpresa" alt="Avatar">

  <span class="{{ margenizquierda }}"></span>

  <!-- <button mat-button routerLinkActive="active" [routerLink]="['/inicio']">
    <span fxHide.xs>{{'Inicio' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-home"></span></span>
  </button> 
 <button mat-button routerLinkActive="active" [routerLink]="['/resumen']">
    <span fxHide.xs>{{'Ultimas facturas' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-home"></span></span>
  </button> -->
  <!-- <button mat-button routerLinkActive="active" [routerLink]="['/resumen']">
    <span fxHide.xs>{{'Inicio' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-home"></span></span>
  </button> -->
  <!-- <button mat-button routerLinkActive="active" [routerLink]="['/perfil']">
    <span fxHide.xs>{{'Perfil' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-user"></span></span>
  </button>
  <button mat-button routerLinkActive="active" [routerLink]="['/informacion','contratos']">
    <span fxHide.xs>{{'Contratos/Facturas' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-file-spreadsheet" matt></span></span>
  </button> -->
  <!-- <button mat-button routerLinkActive="active" [routerLink]="['/informacion','facturas']"
    *ngIf="tipoCliente !== '3' && tipoCliente !== '2'">
    <span fxHide.xs>{{'Facturas' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-file-invoice"></span></span>
  </button> -->
  <button *ngIf="(MostrarConsumosPorHora && tipoCliente == '3') || 
  (MostrarConsumosPorHoraClientes && (tipoCliente == '2' || tipoCliente == '1'))"
   mat-button routerLinkActive="active" [routerLink]="['/cch']">
    <span fxHide.xs>{{'Consumos por hora' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-chart-line"></span></span>
  </button>

  <span class="{{ margenderecha }}"></span>
  <app-translation *ngIf="idiomas"></app-translation>
  <button mat-button>     
    <!-- <span fxHide.xs class="mr-10">{{'Usuario' | translate}}</span> -->
    <span fxHide.xs class="mr-10">{{NombreCliente}}</span>
    <!-- <span class="fal fa-ellipsis-v"></span> -->
  </button>
  <button class="cerrar"  mat-button [routerLink]="['/salir']">
    <!-- <i class="fas fa-times"></i> -->
    <div fxFlex="80" class="text">{{'Cerrar sesión' | translate}}</div>
    <!-- <div fxFlex="20" class="icon"> <span class="fal fa-sign-out"></span></div> -->
    <div fxFlex="20" class="icon"> <span class="fas fa-user-times"></span></div>
   <!-- <div fxFlex="80">{{'Cerrar sesión' | translate}}</div> -->
  </button>
  

   <!-- Botón para cerrar la pestaña -->
   
   <!-- * Funcionalidad que nos han solicitado para que sea posible cerrar desde un botón 
        situado junto al nombre del cliente las pestañas que éste abra.* -->
 
   <!-- * Solo funciona en las pestañas emergentes que abramos, pero se muestra también
    en la pestaña principal, habría que ver como cambiar ésto * -->
  
  <!-- <button mat-button (click)="cerrar()">
    <i class="fas fa-times"></i>
  </button> -->

   <!-- *** -->

  <!-- <mat-menu #menuRight="matMenu">
    <button mat-menu-item [routerLink]="['/password']">
      <div fxFlex="20"><span class="fal fa-key"></span></div>
      <div fxFlex="80">{{'Cambiar contraseña' | translate}}</div>
    </button>
    <button mat-menu-item [routerLink]="['/salir']">
      <div fxFlex="20"><span class="fal fa-sign-out"></span></div>
      <div fxFlex="80">{{'Cerrar sesión' | translate}}</div>
    </button>
  </mat-menu> 
                *** HAN PEDIDO ELIMINAR CAMBIO DE CONTRASEÑA Y PONER EL CERRAR EN EL MENÚ PRINCIPAL ***

-->
</mat-toolbar>

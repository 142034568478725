import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  name?: string;
  address?: string;
  email?: string;
  phone?: string;
  urlAvisoLegal?: string;
  urlCookies?: string;
  constructor() { }

  ngOnInit() {
    this.name = environment.companyName;
    this.address = environment.companyAddress;
    this.email = environment.companyEmail;
    this.phone = environment.companyPhone;
    this.urlAvisoLegal = environment.urlAvisoLegal;
    this.urlCookies = environment.urlCookies;
  }

}

<app-header></app-header>
    <!-- La idea de este componente es que exista una sección donde los administradores de fincas puedan consultar información
    de interés para su actividad -->
<div class="wrapper">
    <!-- Botón para volver a la página de inicio -->
    <button class="return" [routerLink]="['/inicio']">
        <i class="fas fa-undo"></i>
        <span> {{'Volver a inicio' | translate}}</span>
    </button>

    <div class="back" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
        <div class="seccion">
                <p class="menu">{{'TRAMOS HORARIOS'| translate}}</p> 
            <a href="https://elekluz.com/tramos-horarios/" target="_blank">
                <img class="imagen" src="assets\img\TramosHorarios.svg">
            </a>
        </div>

        <!-- ************************************************ -->

        <div class="seccion">
                <p class="menu">{{'MAPA DISTRIBUIDORA'| translate}}</p>
            <a href="https://elekluz.com/mapas-distribuidoras/" target="_blank">
                <img class="imagen" src="assets\img\MapaDistribuidora.svg">
            </a>
        </div>

        <!-- ************************************************ -->

        <div class="seccion">
            <p class="menu">{{'INFÓRMATE'| translate}}</p>
            <a>
                <img class="imagen" src="assets\img\Informate.svg">
            </a>
        </div>
    </div>
</div>
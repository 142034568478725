import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Global } from '../../../interfaces/config/global';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor() {}
  url: any;
  urlOV = environment.urlApi;
  urlCCH = environment.urlCch;
  cryptoKey= environment.cryptoKey;
  cryptoIV= environment.cryptoIV;
  // cryptoKey= 'c2DTqIULUp8Tgc6iQwGDpkdLsGHhbN8H';
  // cryptoIV= 'G47qvKG29WV7RjUi';
  user = environment.user;
  password = environment.password;

  getUrlApi(): string {
    return this.urlOV;
  }

  getUsuarioApi(): Global {
    const body = { Usuario: this.user, Password: this.password };
    return body;
  }

  getUrlApiCch() {
    return this.urlCCH;
  }

  getHttpHeaders(): object {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      })
    };
    return options;
  }

  getCryptoKey(): string {
    if(this.cryptoKey == ''){
      return 'c2DTqIULUp8Tgc6iQwGDpkdLsGHhbN8H'
    }else{
      return this.cryptoKey;
    }
  }

  getCryptoIV(): string {
    if(this.cryptoKey == ''){
      return 'G47qvKG29WV7RjUi'
    }else{
      return this.cryptoIV;
    }
  }
}

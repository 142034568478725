import { Component, OnInit, Inject, ɵConsole, Input } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { MailService } from "src/app/services/mail/mail.service";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Email } from "../../../interfaces/Email";
import { environment } from "src/environments/environment";
import { LoginService } from "../../../services/login/login.service";
import { FuncionesService } from "../../../services/funciones/funciones.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-modificaciones-dialog.component",
  templateUrl: "./modificaciones-dialog.component.html",
  styleUrls: ["./modificaciones-dialog.component.scss"],
})
export class ModificacionesComponent implements OnInit {
  // declarations
  //option:any;
  // @Input() CodigoContrato: any;
  form: FormGroup;
  texto: any;
  asunto: any;
  CodigoContrato: any;
  tokenvalidado: any;
  IsAdministrador: any;
  Email: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _mailService: MailService,
    private _loginService: LoginService,
    private _funcionesService: FuncionesService,
    public dialogRef: MatDialogRef<ModificacionesComponent>
  ) {
    this.dialogRef.updateSize("100%", "100%");
   
  }

  ngOnInit() {
    this.CodigoContrato=this.data.CodigoContrato;
    console.log(this.data);
    this.form = this.fb.group({
      texto: new FormControl(),
      option: new FormControl(),
    });
    if (this._loginService.datosTitular === undefined) {
      this._loginService
        .getvalidaToken(localStorage.getItem("idCliente"))
        .subscribe((data) => {
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if (this.tokenvalidado.Valido == true) {
            this._loginService
              .getLogin(
                this._funcionesService.decodificarToken(
                  JSON.parse(localStorage.getItem("u"))
                ),
                this._funcionesService.decodificarToken(
                  JSON.parse(localStorage.getItem("p"))
                )
              )
              .toPromise()
              .then(async (data) => {
                this._loginService.datosTitular = this._funcionesService.decodificarToken(
                  data
                );
                console.log(this._loginService.datosTitular);
                this.loadData();
              });
          } else {
            this.router.navigate(["/salir"]);
          }
        });
    } else {
      this.loadData();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  consultar() {
    let cuerpo = "";
    let replyTo = "";

    cuerpo =
      "<h3>El cliente con código de contrato " +
      this.CodigoContrato +
      " solicita los siguientes cambios en su perfil: " +
      "</h3>";
    this.asunto = "Solicitud cambio datos de cliente de la oficina virtual";
    replyTo != ""
      ? (cuerpo =
          "<p>El cliente con email: " + this.data.EmailContacto + "  </p>")
      : null;
    if (this.form.value.texto) {
      cuerpo = cuerpo + "<p>" + this.form.value.texto + "</p>";
    }
    cuerpo =
      cuerpo +
      "<p>Ruego remitan confirmacion: " + this.Email +
      "</p>" +
      "<br>" +
      "<p>Un saludo</p>";

    let email: Email = {
      Asunto: this.asunto,
      Destinatario: environment.email,
      // Destinatario: 'mario.burillo@audinforsystem.es',
      Cuerpo: cuerpo,
      ReplyTo: replyTo,
    };
    this._mailService.getEnvioEmail(email).subscribe(async (data) => {
      // this.isLoading = false;
      // console.log(data);
    });
    this.dialogRef.close();
  }

  clickEleccion() {
    // this.dialogRef.close(this.eleccion);
  }

  loadData() {
    this.IsAdministrador = localStorage.getItem("isAdminFincas") === "true";
    if (this.IsAdministrador) {
      // this.NombreCliente = this._loginService.datosTitular.NombreCliente;
      // this.Apellido1Cliente = this._loginService.datosTitular.Apellido1Cliente;
      // this.Apellido2Cliente = this._loginService.datosTitular.Apellido2Cliente;
      // this.RazonSocialCliente = this._loginService.datosTitular.NombreAdministrador;
      // this.CiudadCliente = this._loginService.datosTitular.CiudadAdministrador;
      // this.CodigoPostalCliente = this._loginService.datosTitular.CodigoPostalAdministrador;
      // this.DireccionCliente = this._loginService.datosTitular.DireccionAdministrador;
      this.Email = this._loginService.datosTitular.EmailAdministrador;
    }
  }
}

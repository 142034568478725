<app-header></app-header>

          <!-- La idea de este nuevo componente es reflejar los datos básicos del punto de suministro
           y un botón desde el cual el administrador pueda solicitar cambios para estos desde un botón destinado para ello
          que abrirá un dialog donde dicho administrador podrá indicar los cambios que solicita para el contrato en concreto
        que desee cambiar -->

<div class="wrapper" *ngIf="!monopunto">

  <button class="return"  [routerLink]="['/inicio']">
    <i class="fas fa-undo"></i>
    <span> {{'Volver a inicio' | translate}}</span>
  </button>
  
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column">
    <div fxFlex="100" [fxFlex.lt-lg]="100">
      <!-- Botón para volver a la página de inicio -->
     
      <mat-card class="of p-0" fxFlex="100">
        <button *ngIf="MostrarDescargaMasivaFacturasPorCups" matTooltip="Descargar facturas" mat-button color="primary"
          [disabled]="this.dataSource.data.length < 1" class="mt-20 primary" [ngClass]="primary"
          (click)="descargaMasivaFacturasDialog()">
          <i class="fas fa-cloud-download-alt"></i>
          {{'Descarga facturas' | translate}}
        </button>
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header> {{'Entorno' | translate}}</span>
              <input class="filter-input" matInput [formControl]="EntornoFilter"/>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg padding" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg padding" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Numero finca -->
          <ng-container matColumnDef="NumFinca" fxFlex="5">
            <th mat-header-cell *matHeaderCellDef  style="width:95px">
              <span mat-sort-header>{{'Nº Finca' | translate}}</span>
              <input class="filter-input" matInput [formControl]="NumFincaFilter" />
            </th>
            <a>
              <td mat-cell *matCellDef="let element"  style="width:95px">{{element.NumFinca}}</td>
            </a>
          </ng-container>

          <!-- Contrato (No quieren que se muestre) No lo eliminamos por si acaso  cambian de idea ¿? -->

          <!-- <ng-container matColumnDef="CodigoContrato" *ngIf="!agrupacontratoscups"> -->
          <!-- <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Contrato' | translate}}</span>
              <input class="filter-input" matInput [formControl]="ContratoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th> -->
          <!-- <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="[ '/informacion', 'perfil', 'contrato', element.IdContrato ]"
                matTooltip="Ver perfil de Contrato">
                {{PrefijoNumeracionContratos}} {{element.CodigoContrato}}
              </a>
            </td>
          </ng-container> -->

          <!-- CUPS -->
          <ng-container matColumnDef="Cups">
            <th mat-header-cell *matHeaderCellDef filter-by="element.Cups">
              <span mat-sort-header> CUPS</span>
              <input class="filter-input" matInput [formControl]="CupsFilter"/>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element.Cups}}
              </span>
            </td>
          </ng-container>
          <!-- Dirección -->
          <ng-container matColumnDef="Direccion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>>{{'Dirección' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.Direccion}}</td>
          </ng-container>
          <!-- Fecha Alta -->
          <ng-container matColumnDef="FechaAlta">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Alta' | translate}}</span>
                <input class="filter-input" matInput [formControl]="FechaAltaFilter"/>
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaAlta | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Vencimiento -->
          <ng-container matColumnDef="FechaVto">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-header-cell>{{'Fecha Vencimiento' | translate}}</span>
              <input class="filter-input" matInput [formControl]="FechaVencimientoFilter" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaVto | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Baja -->
          <ng-container matColumnDef="FechaBaja">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Baja' | translate}}</span>
              <input class="filter-input" matInput [formControl]="FechaBajaFilter" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaBaja | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          
          <!-- Identidad -->
          <ng-container matColumnDef="Identidad">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header>{{'Identidad' | translate}}</span>
              <!-- <span mat-sort-header style="margin-top: 10px;">{{'CIF' | translate}}</span> -->
              <input class="filter-input" matInput [formControl]="IdentidadFilter"/>

              <!-- [routerLink]="[ '/informacion', 'perfil', element.IdCliente+'&'+element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]" -->
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
              <span> {{element.Identidad}}</span>
            </td>
          </ng-container>

          <!-- DireccionSuministro -->
          <ng-container matColumnDef="DireccionSuministro">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header>{{'Dirección Suministro' | translate}}</span>
              <input class="filter-input" matInput [formControl]="DireccionSuministroFilter" />
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
              {{element.DireccionSuministro}}
            </td>
          </ng-container>

          <!-- Denominación -->
          <ng-container matColumnDef="NombreCliente">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header>{{'DenominacionCliente' | translate}}</span>
              <input class="filter-input" matInput [formControl]="NombreClienteFilter" />
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
              <span> {{element.NombreCliente}}</span>
            </td>
          </ng-container>

          <!-- Tarifa -->

          <!-- <ng-container matColumnDef="Tarifa" style="display: none;">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Tarifa' | translate}}</span>
              <input class="filter-input" matInput [formControl]="TarifaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.Tarifa}}</td>
          </ng-container> -->
          <!-- 
          {{element}} -->

          <!-- ******************************************************************************************************************** -->
          
          <!-- Deuda -->
             <!-- No quieren que aparezca esta información -->
          <!-- <ng-container matColumnDef="Deuda">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Deuda' | translate}}</span>
              <input class="filter-input" disabled matInput [formControl]="FechaAltaFilter"
                style="visibility: collapse;" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="Tiene deuda pendiente en las facturas" class="alert" style="color:red;"
                *ngIf="element.Deuda > 0"> <i class="fas fa-times-circle"></i> {{element.Deuda |
                currency:'EUR':'symbol':'':'es'}}</span>
              <span matTooltip="Sin deuda" class="alert"><i class="fas fa-check-circle"
                  *ngIf="element.Deuda <= 0"></i></span>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="Detalles">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Modificaciones' | translate}}</span>
              <input class="filter-input" disabled matInput [formControl]="FechaAltaFilter" style="visibility: collapse;"/>
            </th>
            <td mat-cell *matCellDef="let element">
              <!--  Inicialmente la idea era que el botón llevase al perfil del cliente donde el administrador
                  pudiese modificar allí los datos, ahora podrá hacerlo sin necesidad de ello, se abrirá directamente el dialog

                  Funcionará??????
                

                --Código:


                <a color="blue" target="_blank"
                [routerLink]="[ '/informacion', 'perfil', 'cliente', element.IdCliente+'&'+element.IdContrato]"
                matTooltip="Modificar datos de suministro">
                     Modificar
                     <i class="far fa-pen"></i>
                  </a>
                 -->
                  <button mat-button color="primary" style="background-color: #00B5E2; font-size: 20px;"
                       (click)="openDialogCambiosDatos(element.CodigoContrato)"> 
                        {{'MODIFICAR' | translate}}
                    <i class="far fa-pen"></i>
                  </button>
            </td>
          </ng-container>

          <!-- Graficos Column ->> No lo quieren en suministro -->
          <!-- <ng-container matColumnDef="Graficos" *ngIf="MostrarListadoContratoBotonGraficos && tipocliente == 3">
            <th mat-header-cell *matHeaderCellDef>{{'Gráficos' | translate}}
              <input class="filter-input" disabled matInput [formControl]="FechaAltaFilter"
                style="visibility: collapse;" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-raised-button color="secondary" [routerLink]="[ '/informacion', 'graficos', element.IdCups ]"
                target="_blank" matTooltip="Ver gráfico">
                <span class="fal fa-chart-bar"></span>
              </a>
            </td>
          </ng-container> -->

          <!-- Boton Facturas Admin Fincas  No lo quieren tampoco en suministro-->
          <!-- <ng-container matColumnDef="Facturas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Facturas' | translate}}
              <input class="filter-input" disabled matInput [formControl]="FechaAltaFilter"
                style="visibility: collapse;" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-raised-button color="primary" *ngIf="element.NumFacturas > 0"
                [routerLink]="[ '/informacion', 'facturas', element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]"
                target="_blank" matTooltip="Ver {{ element.NumFacturas }} facturas" matBadge="{{ element.NumFacturas }}"
                matBadgeColor="warn">
                <span class="fal fa-layer-group fa-lg"></span>
              </a>
            </td>
          </ng-container> -->

          <!-- Boton Graficos Admin Fincas -->
          <!-- <ng-container matColumnDef="Graficos" *ngIf="MostrarListadoContratoDenominacionCliente && TipoCliente === '3'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Graficos' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <a mat-raised-button color="primary"
                [routerLink]="[ '/graficos', 'graficos', element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]"
                matTooltip="Ver gráficos" matBadge="{{ element.NumFacturas }}"
                matBadgeColor="warn">
                <span class="fal fa-layer-group fa-lg"></span>
              </a>
            </td>
          </ng-container> -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <!-- <button *ngIf="MostrarDescargaMasivaFacturasPorCups" matTooltip="Descargar facturas" mat-button color="primary" [disabled]="this.dataSource.data.length < 1"
           class="mt-20 primary" [ngClass]="primary" (click)="descargaMasivaFacturasDialog()">
          <i class="far fa-file-archive"></i> {{'Descarga facturas' | translate}}
        </button> -->
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<app-footer *ngIf="!monopunto"></app-footer>

<app-perfil-contrato [codigo]="contratos[0].IdContrato" *ngIf="monopunto"></app-perfil-contrato>
import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { VERSION } from '@angular/material';

import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tpv-dialog',
  templateUrl: './tpv-dialog.component.html',
  styleUrls: ['./tpv-dialog.component.scss']
})
export class TpvDialogComponent implements OnInit {

  importeTotal: any;
  fechaFactura: any;
  numeroFactura: any;
  isDisable = true;

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TpvDialogComponent>) {
    if (data) {
      this.importeTotal = data.importeTotal;
      this.fechaFactura = data.fechaFactura;
      this.numeroFactura = data.numeroFactura;
    }
    this.dialogRef.updateSize('400px', '400px');
  }

  ngOnInit() {
    this.form = this.fb.group({
      tarjetaNumeracion: new FormControl(),
      tarjetaNombre: new FormControl(),
      tarjetaMes: new FormControl(),
      tarjetaAno: new FormControl(),
      tarjetaCcv: new FormControl()
    });
  }

  onChangeValue(): void {

    // console.log(this.form.get('tarjetaNombre').value);
    // console.log(this.form.get('tarjetaNumeracion').value);
    // console.log(this.form.get('tarjetaMes').value);
    // console.log(this.form.get('tarjetaAno').value);
    // console.log(this.form.get('tarjetaCcv').value);

    if (this.form.get('tarjetaNombre').value !== null  && this.form.get('tarjetaMes').value !== null && this.form.get('tarjetaAno').value !== null && this.form.get('tarjetaCcv').value !== null) {
      if (this.form.get('tarjetaNombre').value.length > 3 && this.form.get('tarjetaNumeracion').value.length === 16 && this.form.get('tarjetaMes').value.length === 2 && this.form.get('tarjetaAno').value.length === 2 && this.form.get('tarjetaCcv').value.length === 3) {
        this.isDisable = false;
      } else {
        this.isDisable = true;
      }
    } else {
      this.isDisable = true;
    }
  }

  onClickPagar(): void {

    this.dialogRef.close(this.form.value);

  }

}

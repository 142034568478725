<app-header></app-header>

<div class="wrapper">

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <!-- Datos generales -->
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px">
      <mat-card fxFlex>
        <mat-card-header>
          <mat-card-title>{{'DATOS GENERALES' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra los datos generales del contrato.' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <span class="backicon fal fa-file-alt"></span>
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Nombre Comercializadora -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Nombre Comercializadora' | translate}}" value="{{NombreComercializadora}}"
                disabled="PerfilContratoNombreComercializadora">
              <span class="fal fa-building" matSuffix></span>
            </mat-form-field>
            <!-- Nombre Distribuidora -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Nombre Distribuidora' | translate}}" value="{{NombreDistribuidora}}"
                disabled="PerfilContratoNombreDistribuidora">
              <span class="fal fa-building" matSuffix></span>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Código Contrato -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código Contrato' | translate}}" value="{{PrefijoNumeracionContratos}} {{CodigoContrato}}"
                disabled="PerfilContratoCodigoContrato">
              <span class="fal fa-barcode" matSuffix></span>
            </mat-form-field>
            <!-- Código CUPS -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código CUPS' | translate}}" value="{{CodigoCups}}" disabled="PerfilContratoCodigoCups">
              <button mat-button matSuffix [routerLink]="[ '/informacion', 'perfil', 'cups', IdCups ]">
                <i class="fal fa-tachometer-alt" aria-hidden="true"></i>
              </button>
            </mat-form-field>
            <!-- Situación del Contrato -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Situación del Contrato' | translate}}" value="{{ContratoSituacion}}"
                disabled="PerfilContratoContratoSituacion">
              <span class="fal fa-file-signature" matSuffix></span>
            </mat-form-field>
            <!-- Número de Contrato de la Distribuidora -->
            <mat-form-field fxFlex>
                <input matInput placeholder="{{'NúmeroContratoDistribuidora' | translate}}" value="{{NumeroContratoDistribuidora}}"
                  disabled="PerfilContratoContratoSituacion">
                <span class="fal fa-file-signature" matSuffix></span>
              </mat-form-field>
            <!-- Identificador -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Titular' | translate}}" value="{{Identidad}}" disabled="PerfilContratoIdentidad" *ngIf="CambiarTitularElekluz">
              <input matInput placeholder="{{'Identificador' | translate}}" value="{{Identidad}}" disabled="PerfilContratoIdentidad" *ngIf="!CambiarTitularElekluz">
               <span class="fal fa-id-card-alt" matSuffix></span>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Fecha Alta -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Fecha Alta' | translate}}" value="{{FechaAlta}}" disabled="PerfilContratoFechaAlta">
              <span class="fal fa-calendar-alt" matSuffix></span>
            </mat-form-field>
            <!-- Fecha Baja -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Fecha Baja' | translate}}" value="{{FechaBaja}}" disabled="PerfilContratoFechaBaja">
              <span class="fal fa-calendar-alt" matSuffix></span>
            </mat-form-field>
            <!-- Fecha Contrato -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Fecha Contrato' | translate}}" value="{{FechaContrato}}"
                disabled="PerfilContratoFechaContrato">
              <span class="fal fa-calendar-alt" matSuffix></span>
            </mat-form-field>
            <!-- Fecha Vencimiento -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Fecha Vencimiento' | translate}}" value="{{FechaVto}}" disabled="PerfilContratoFechaVto">
              <span class="fal fa-calendar-alt" matSuffix></span>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Provncia-->
            <mat-form-field fxFlex="20">
              <input matInput placeholder="{{'Provincia' | translate}}" value="{{TextoProvincia}}" disabled="PerfilContratoTextoProvincia">
              <span class="fal fa-map-marked-alt" matSuffix></span>
            </mat-form-field>
            <!-- Ciudad -->
            <mat-form-field fxFlex="20">
              <input matInput placeholder="{{'Ciudad'|translate}}" value="{{TextoCiudad}}" disabled="PerfilContratoTextoCiudad">
              <span class="fal fa-map-marker-alt" matSuffix></span>
            </mat-form-field>
            <!-- Dirección -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Dirección'|translate}}" value="{{Direccion}}" disabled="PerfilContratoDireccion">
              <span class="fal fa-address-book" matSuffix></span>
            </mat-form-field>
             <!-- Codigo Postal-->
             <mat-form-field fxFlex="10">
              <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostal}}" disabled="PerfilContratoCodigoPostal">
              <span class="fal fa-map-pin" matSuffix></span>
            </mat-form-field>
          </div>

        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="(!PerfilContratoNombreComercializadora ||
                !PerfilContratoNombreDistribuidora ||
                !PerfilContratoCodigoContrato ||
                !PerfilContratoCodigoCups ||
                !PerfilContratoContratoSituacion ||
                !PerfilContratoIdentidad ||
                !PerfilContratoFechaAlta ||
                !PerfilContratoFechaBaja ||
                !PerfilContratoFechaContrato ||
                !PerfilContratoFechaVto ||
                !PerfilContratoTextoCiudad ||
                !PerfilContratoDireccion) && OcultarContactosEleia == false">
          <button mat-button>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!-- PRECIOS DEL CONTRATO -->
  <!--<div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="MostrarPreciosContrato">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px">
      <mat-card fxFlex>
        <mat-card-header>
          <mat-card-title>{{'PRECIOS' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra los datos generales del contrato.' | translate}}3</mat-card-subtitle>
        </mat-card-header>
        <span class="backicon fal fa-file-alt"></span>
        <mat-card-content>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
              Test
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>-->




  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px">
      <!-- Datos de facturación -->
      <mat-card fxFlex="50">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE FACTURACIÓN' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra los datos de facturacion del contrato.' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <span class="backicon fal fa-file-invoice"></span>
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Tarifa -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Tarifa'| translate}}" value="{{TarifaPeaje}}" disabled="PerfilContratoTarifa">
              <span class="fal fa-chart-bar" matSuffix></span>
            </mat-form-field>
            <!-- Tarifa Grupo -->
            <mat-form-field fxFlex *ngIf="MostrarTarifaGrupo">
              <input matInput placeholder="{{'Tarifa Grupo' | translate}}" value="{{TarifaGrupo}}" disabled="PerfilContratoTarifaGrupo">
              <span class="fal fa-chart-bar" matSuffix></span>
            </mat-form-field>
            <!-- Tarifa -->
            <mat-form-field fxFlex *ngIf="Deuda < 0">
              <input matInput placeholder="{{'Deuda'| translate}}" value="{{Deuda}}" disabled="true">
              <span class="fal fa-euro-sign" matSuffix></span>
            </mat-form-field>
          </div>

          <!-- PRECIOS POTENCIA Y ENERGIA DEL CONTRATO -->
          <div *ngIf="MostrarPreciosContrato && PreciosPotencias">
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioPotencia!=null"><label>{{'Precio Potencia'| translate}} €/kW día</label></div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioPotencia!=null">
              <div fxFlex *ngFor="let PrecioPotencias of PreciosPotencias.PreciosPotencias; let i = index">
                <mat-form-field fxFlex *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
                && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)">
                  <input matInput placeholder="{{PrecioPotencias.TextoPotencia}}" value="{{PrecioPotencias.PrecioPotencia}} {{i}}" disabled="true">
                  <span class="fal fa-euro-sign" matSuffix></span>
                </mat-form-field>
              </div>
            </div>


            <!-- Precio energia + FEE -->
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia!=null && (PreciosPotencias.Fee!=null && PreciosPotencias.Fee)"><label>{{'Precio Energía'| translate}} €/kWh</label></div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia!=null && (PreciosPotencias.Fee!=null && PreciosPotencias.Fee)">
              <div fxFlex *ngFor="let PrecioPotencias of PreciosPotencias.PreciosPotencias; let i = index">
                <mat-form-field fxFlex *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
                  && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)">
                  <input matInput placeholder="{{PrecioPotencias.TextoPotencia}}" value="{{PrecioPotencias.PrecioEnergia + (PreciosPotencias.Fee/1000)}}" disabled="true">
                  <span class="fal fa-euro-sign" matSuffix></span>
                </mat-form-field>
              </div>
            </div>
            <!-- Precio energia SIN Fee -->
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia!=null && !PreciosPotencias.Fee"><label>{{'Precio Energía'| translate}} €/kWh</label></div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia!=null && !PreciosPotencias.Fee">
              <div fxFlex *ngFor="let PrecioPotencias of PreciosPotencias.PreciosPotencias; let i = index">
                <mat-form-field fxFlex *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
                && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)">
                  <input matInput placeholder="{{PrecioPotencias.TextoPotencia}}" value="{{PrecioPotencias.PrecioEnergia}}" disabled="true">
                  <span class="fal fa-euro-sign" matSuffix></span>
                </mat-form-field>
              </div>
            </div>
            <!-- SI VIENE Precio energia VACIO pero CON FEE -->
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia == null && PreciosPotencias.Fee"><label>{{'Precio Energía'| translate}} €/MWh</label></div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="PreciosPotencias.PreciosPotencias[0].PrecioEnergia == null && PreciosPotencias.Fee">
              <div fxFlex *ngFor="let PrecioPotencias of PreciosPotencias.PreciosPotencias; let i = index">
                <mat-form-field fxFlex *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
                && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)">
                  <input matInput placeholder="{{PrecioPotencias.TextoPotencia}}" value="{{PreciosPotencias.Fee | number: '1.2-2'}}" disabled="true">
                  <span class="fal fa-euro-sign" matSuffix></span>
                </mat-form-field>
              </div>
            </div>


          </div>

          <!-- <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20"> -->
            <!-- Perfil Facturación -->
            <!-- <mat-form-field fxFlex>
              <input matInput placeholder="Perfil Facturación" value="{{PerfilFacturacion}}"
                disabled="PerfilContratoPerfilFacturacion">
              <span class="fal fa-chart-bar" matSuffix></span>
            </mat-form-field>
          </div> -->
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end"
          *ngIf="!PerfilContratoTarifa || !PerfilContratoTarifaGrupo || !PerfilContratoPerfilFacturacion">
          <button mat-button>{{'Solicitar cambios.' | translate}}</button>
        </mat-card-actions>
      </mat-card>
      <!-- Datos de potencias -->
      <mat-card fxFlex="50">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE POTENCIAS' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra los datos de los periodos del contrato.' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <span class="backicon fal fa-file-chart-line"></span>
        <mat-card-content>
          <div fxLayout="row wrap" fxLayoutGap="20px" class="m-20">
            <div fxFlex *ngFor="let potencia of Potencias; let i = index">
              <div fxFlex>
                <mat-form-field *ngIf="!((TarifaPeaje == '2.0DHA' || TarifaPeaje == '2.1DHA' || TarifaPeaje == '2.0DHS' || TarifaPeaje == '2.1DHS')
                && MostrarPerfilContratoSoloUnaPotenciaDHA && i == 1)">
                  <input matInput placeholder="{{potencia.TextoPotencia}}" value="{{potencia.PotenciaContratada}}"
                    disabled>
                  <span class="fal fa-tachometer-alt-average" matSuffix></span>
                </mat-form-field>
                <!-- TODO: Pediente de desarrrollo; mostrar precio de potencia y de energia que se está facturando al suministro -->
                <!-- <div><i class="far fa-level-up fa-flip-horizontal"></i> {{potencia.PotenciaContratada*5}} €</div> -->
              </div>
            </div>
          </div>
        </mat-card-content>
        <!-- <mat-card-actions fxLayout="row" fxLayoutAlign="end end">
          <button mat-button >Solicitar cambios</button>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </div>

</div>

<app-footer></app-footer>

import { Component, isDevMode } from '@angular/core';

@Component({
  selector: 'app-informacion-interes',
  templateUrl: './informacion-interes.component.html',
  styleUrls: ['./informacion-interes.component.scss']
})

export class InformacionInteresComponent {
   constructor( ){

     }

}



<app-header></app-header>


<div class="wrapper">

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxLayoutAlign="center stretch" >
        <button class="cerrar" mat-button (click)="cerrar()" fxLayoutGap="20px">
          <i class="fas fa-times"></i>
          <span class="botontexto">{{'CERRAR PESTAÑA'  | translate}}</span>
        </button> 
      </mat-card>
    </div>
  </div>
  


  <!-- Gráficos 1º Linea -->
  <!-- Monopunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '1'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoImportesFacturasMonopunto" class="grafico">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoConsumosMonopunto" class="grafico">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

  <!-- Multipunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '2' || TipoCliente === '3'">
    
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
    
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoFacturasMultipunto" class="grafico">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoConsumosMultipunto" class="grafico">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

  <!-- Gráficos 2ª linea -->
  <!-- Monopunto / Multipunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '1' || TipoCliente === '2' || TipoCliente === '3'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaImportesMonopunto" class="grafico"
          *ngIf="TipoCliente === '1'">
        </highcharts-chart>
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaImportesMultipunto" class="grafico"
          *ngIf="TipoCliente === '2' || TipoCliente === '3'">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaConsumosMonopunto" class="grafico"
          *ngIf="TipoCliente === '1'">
        </highcharts-chart>
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaConsumosMultipunto" class="grafico"
          *ngIf="TipoCliente === '2' || TipoCliente === '3'">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

</div>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { PasswordService } from '../../services/password/password.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { ConditionalExpr } from '@angular/compiler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recordar-contrasena',
  templateUrl: './recordar-contrasena.component.html',
  styleUrls: ['./recordar-contrasena.component.scss']
})
export class RecordarContrasenaComponent implements OnInit {

  identificador:any;
  marca: any;

  constructor(
    private _funcionesService: FuncionesService,
    private _passwordService: PasswordService,
    public dialog: MatDialog,
    private _loginService: LoginService,
    private route: Router
  ) { 

    this.marca = environment.marca;

  }

  recuperarPassword(f: NgForm){
    if (f.valid) {

        if(this.marca == ""){

          this._passwordService.getRecuperarPassword(f.value.identificador).subscribe(
            data => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Contraseña restablecida correctamente',
                  texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.'
                }
              });
            },
            err => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Error al restablecer la contraseña',
                  texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                }
              });
            });

        }else{

          this._passwordService.getRecuperarPasswordMarca(f.value.identificador,this.marca).subscribe(
            data => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Contraseña restablecida correctamente',
                  texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.'
                }
              });
            },
            err => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Error al restablecer la contraseña',
                  texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                }
              });
            });

        }

      }
    }

  ngOnInit() {
  }

}

<app-header></app-header>
<div class="wrapper">
<!-- Botón para volver a la página de inicio -->
<button class="return"  [routerLink]="['/inicio']">
  <i class="fas fa-undo"></i>
  <span> {{'Volver a inicio' | translate}}</span>
</button> 
      
<!-- Administrador de fincas -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="TipoCliente === '3'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px">
     
      <!-- Clientes -->
    </div>
  </div>
 <!-- Monopunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="TipoCliente === '1'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxFlex="30" [fxFlex.lt-lg]="100">
        <mat-card-title-group style="display: none;" >
          <mat-card-title class="titleTarifa">{{tarifaMonopunto}}</mat-card-title>
          <mat-card-subtitle *ngIf="MostrarTarifaGrupo">{{'Grupo Tarifa:' | translate}}{{tarifaGrupoMonopunto}}</mat-card-subtitle>
          <span class="far fa-lightbulb fa-3x" *ngIf="entornoMonopunto === 'Luz'" aria-hidden="true"></span>
          <span class="far fa-fire fa-3x" *ngIf="entornoMonopunto === 'Gas'" aria-hidden="true"></span>
        </mat-card-title-group>
        <mat-card-content fxLayout="row">
          <span class="titleCups">{{cupsMonopunto}}</span>
          <div fxLayout="column" fxFlex="80">
            <span *ngIf="perfilFacturacionMonopunto">
              <i class="fal fa-fw fa-file-invoice" aria-hidden="true"></i>
              {{perfilFacturacionMonopunto}}
            </span>
            <span *ngIf="direccionMonopunto">
              <i class="fal fa-fw fa-address-card" aria-hidden="true"></i>
              {{direccionMonopunto}}
            </span>
          </div>
        </mat-card-content>
        <mat-card-actions fxLayout="row">
          <!-- <button mat-button>Ver detalles</button> -->
        </mat-card-actions>
      </mat-card>
      <mat-card fxFlex="70" [fxFlex.lt-lg]="100" class="of p-0">
        <table mat-table [dataSource]="dataSourceUltimasFacturas" matSort matSortActive="FechaFactura"
          matSortDirection="desc">
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Entorno' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Factura -->
          <ng-container matColumnDef="Factura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Factura' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="SimplificacionSerieFactura && isNumber(element.Serie)">{{element.NumFactura}}</ng-container>
                <ng-container *ngIf="!(SimplificacionSerieFactura && isNumber(element.Serie))">{{element.NumFactura}}</ng-container>
            </td>

          </ng-container>
          <!-- Fecha Factura -->
          <ng-container matColumnDef="FechaFactura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fecha Factura' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.FechaFactura | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <!-- Importe total -->
          <ng-container matColumnDef="ImporteTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Importe' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.ImporteTotal | currency:'EUR':'symbol':'':'es'}} </td>
          </ng-container>
          <!-- Estado -->
          <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Estado' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.Estado; else elseEstadoBlock">
                <button mat-button disabled>
                  <span class="fal fa-check-circle fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseEstadoBlock>
                <button mat-button disabled>
                  <span class="fal fa-exclamation-circle fa-lg"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>
          <!-- Descargar -->
          <ng-container matColumnDef="Descargar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Descargar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdContratoDocumento">
                <button mat-button (click)="descargaFactura(element)">
                  <span class="fal fa-file-download fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseDescargarBlock>
                <button mat-button disabled>
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>
          
   <!-- Pagar -->
          <!-- <ng-container matColumnDef="Pagar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Pagar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="!element.Estado" mat-button (click)="redsysdialog(element)">
                <span class="fal fa-credit-card fa-lg" matTooltip="Pagar ahora"></span>
              </button>
              <button *ngIf="element.Estado" mat-button (click)="expression" disabled>
                <span class="fal fa-credit-card-blank fa-lg" matTooltip="Pago completado"></span>
              </button>
            </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="columnasUltimasFacturasMesMonopunto"></tr>
          <tr mat-row *matRowDef="let row; columns: columnasUltimasFacturasMesMonopunto;"></tr>
        </table>
     
        <div *ngIf="textoTablaUltimasFacturasVaciaMonopunto"
          style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
          <span>{{'No hay datos disponibles del último mes.' | translate}}</span>
        </div>
          
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[2]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>

  <!-- Multipunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="TipoCliente === '2'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <!-- Contador contratos -->
      <mat-card fxFlex="30" [fxFlex.lt-lg]="100">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <span class="fa-stack fa-3x">
            <i class="fal fa-file fa-stack-2x"></i>
            <i class="fas fa-lightbulb fa-stack-1x"></i>
          </span>
          <h1 class="m-0 mt-20">{{ numContratosLuz }}</h1>
          <span class="mat-body">{{'Contratos Electricidad' | translate}}</span>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" *ngIf="numContratosGas > 0">
          <span class="fa-stack fa-3x">
            <i class="fal fa-file fa-stack-2x"></i>
            <i class="fas fa-fire fa-stack-1x"></i>
          </span>
          <h1 class="m-0 mt-20">{{ numContratosGas }}</h1>
          <span class="mat-body">{{'Contratos Gas' | translate}}</span>
        </div>
     </mat-card>
      <!-- Facturas último mes -->
      <mat-card fxFlex="70" [fxFlex.lt-lg]="100" class="of">
        <table mat-table [dataSource]="dataSourceUltimasFacturas" matSort matSortActive="FechaFactura"
          matSortDirection="desc">
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Entorno' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Factura -->
          <ng-container matColumnDef="Factura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Factura' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="!element.Serie && !element.NumFactura; else elseNombreFacturaBlock">
                <i class="text-muted">{{'Sin nombre' | translate}}</i>
              </ng-container>
              <ng-template #elseNombreFacturaBlock>
                <ng-container *ngIf="SimplificacionSerieFactura && isNumber(element.Serie)">{{element.NumFactura}}</ng-container>
                <ng-container *ngIf="!(SimplificacionSerieFactura && isNumber(element.Serie))">{{element.NumFactura}}</ng-container>
              </ng-template>
            </td>
          </ng-container>
          <!-- Fecha Factura -->
          <ng-container matColumnDef="FechaFactura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fecha Factura' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.FechaFactura | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <!-- Importe total -->
          <ng-container matColumnDef="ImporteTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Importe' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.ImporteTotal | currency:'EUR':'symbol':'':'es'}}</td>
          </ng-container>
          <!-- Estado -->
          <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Estado' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.Estado; else elseEstadoBlock">
                <button mat-button disabled>
                  <span class="fal fa-check-circle fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseEstadoBlock>
                <button mat-button disabled>
                  <span class="fal fa-exclamation-circle fa-lg"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>
          <!-- Descargar -->
          <ng-container matColumnDef="Descargar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Descargar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdContratoDocumento; else elseDescargarBlock">
                <button mat-button (click)="descargaFactura(element)">
                  <span class="fal fa-file-download fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseDescargarBlock>
                <button mat-button disabled>
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <!-- Pagar -->
          <ng-container matColumnDef="Pagar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Pagar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="!element.Estado" mat-button (click)="redsysdialog(element)">
                <span class="fal fa-credit-card fa-lg" matTooltip="Pagar ahora"></span>
              </button>
              <button *ngIf="element.Estado" mat-button (click)="expression" disabled>
                <span class="fal fa-credit-card-blank fa-lg" matTooltip="Pago completado"></span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnasUltimasFacturasMesMP"></tr>
          <tr mat-row *matRowDef="let row; columns: columnasUltimasFacturasMesMP;"></tr>
        </table>
        <div *ngIf="spinnerLoadingUltimasFacturasMultipunto"
          style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
          <mat-progress-spinner [diameter]="45" color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="textoTablaUltimasFacturasVacia"
          style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
          <span> {{'No hay datos disponibles del último mes.' | translate}}</span>
        </div>
        <!-- Facturas impagadas -> Han solicitado ocultarlas

           <button *ngIf="MostrarBotonFacturasImpagadas && pendientespago > 0" mat-button color="primary"
          matTooltip="Mostrar sólo facturas impagadas" (click)="mostrarSoloFacturasImpagadas()" ><i
            class="fas fa-file-exclamation"></i> {{'Mostrar impagadas' | translate}}</button>
          
          -->
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[2]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>

  <!-- Gráficos 1º Linea -->
  <!-- Monopunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '1'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoImportesFacturasMonopunto" class="grafico">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoConsumosMonopunto" class="grafico">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

  <!-- Multipunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="TipoCliente === '2'
   || (MostrarResumenGraficosDeMultipuntoAAdministradorFincas && TipoCliente === '3')">
    <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex='20'>
            <mat-select placeholder="CUPS" (selectionChange)="BusquedaNuevoCups($event.value)"
              [formControl]="selectedCups" [(ngModel)]="CupsMultipunto" [disabled]="TamanoListadoCupsCliente == 1">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="search($event.target.value)" autocomplete="off" placeholder="{{'Buscar Cups' | translate}}">
              <mat-divider></mat-divider>
              <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{ Cups.CodigoCups }}</mat-option>
            </mat-select>
          </mat-form-field>
          <span class="fal fa-lightbulb spanEntorno" *ngIf="Entorno === 'Luz'" matSuffix></span>
          <span class="fal fa-fire spanEntorno" *ngIf="Entorno === 'Gas'" matSuffix></span>
          <mat-form-field fxFlex *ngIf="RazonSocial">
            <input matInput placeholder="{{'Razón Social' | translate}}" value="{{RazonSocial}}" disabled>
            <span class="fal fa-address-book" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex='10'>
            <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostal}}" disabled>
            <span class="fal fa-map-pin" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex='10'>
            <input matInput placeholder="{{'Ciudad' | translate}}" value="{{Ciudad}}" disabled>
            <span class="fal fa-city" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex='10'>
              <input matInput placeholder="{{'Provincia' | translate}}" value="{{Provincia}}" disabled>
              <span class="fal fa-map-marked-alt" matSuffix></span>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
         <mat-form-field fxFlex>
            <mat-select placeholder="{{'Dirección' | translate}}" (selectionChange)="BusquedaNuevoCups($event.value)"
                [formControl]="selectedCups" [(ngModel)]="CupsMultipunto" [disabled]="TamanoListadoCupsCliente == 1">
              <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{Cups.Direccion}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{'Tarifa' | translate}}" value="{{Tarifa}}" disabled>
            <span class="fal fa-chart-bar" matSuffix></span>
          </mat-form-field>
          <mat-form-field  *ngIf="MostrarTarifaGrupo" fxFlex>
            <input  matInput placeholder="{{'Tarifa Grupo' | translate}}" value="{{TarifaGrupo}}" disabled>
            <span class="fal fa-chart-bar" matSuffix></span>
          </mat-form-field>
          <mat-form-field  *ngIf="MostrarPerfilFacturacion" fxFlex>
            <input matInput placeholder="{{'Perfil Facturación' | translate}}" value="{{PerfilFacturacion}}" disabled>
            <span class="fal fa-chart-bar" matSuffix></span>
          </mat-form-field>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '2' || (MostrarResumenGraficosDeMultipuntoAAdministradorFincas && TipoCliente === '3')">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoFacturasMultipunto" class="grafico">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoConsumosMultipunto" class="grafico">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

  <!-- Gráficos 2ª linea -->
  <!-- Monopunto / Multipunto -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '1' || TipoCliente === '2' 
    || (MostrarResumenGraficosDeMultipuntoAAdministradorFincas && TipoCliente === '3')">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaImportesMonopunto" class="grafico"
          *ngIf="TipoCliente === '1'">
        </highcharts-chart>
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaImportesMultipunto" class="grafico"
          *ngIf="TipoCliente === '2' || (MostrarResumenGraficosDeMultipuntoAAdministradorFincas && TipoCliente === '3')">
        </highcharts-chart>
      </mat-card>
      <mat-card fxFlex="50">
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaConsumosMonopunto" class="grafico"
          *ngIf="TipoCliente === '1'">
        </highcharts-chart>
        <highcharts-chart [Highcharts]="Highcharts" [options]="graficoComparativaConsumosMultipunto" class="grafico"
          *ngIf="TipoCliente === '2' || (MostrarResumenGraficosDeMultipuntoAAdministradorFincas && TipoCliente === '3')">
        </highcharts-chart>
      </mat-card>
    </div>
  </div>

   <!-- Admin Fincas -->
   <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '3'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <!-- Últimas facturas -->
      <mat-card fxFlex="100" class="of p-0">
        <div class="container-title-table">
          <div class="table-title">{{'Últimas Facturas' | translate}}</div>
          <div class="table-subtitle">{{'Desde' | translate}} {{fechaInicio | date: 'dd/MM/yyyy'}} {{'hasta' | translate}}
            {{fechaHoy | date: 'dd/MM/yyyy'}}</div>
            <!-- <button mat-button color="primary"
            matTooltip="Descargar últimas facturas" (click)="descargarUltimasFacturas()">
            <i class="fas  fa-cloud-download-alt"></i>
              <span > {{'Descargar últimas facturas' | translate}}</span>
            </button> -->
          <mat-divider></mat-divider>
        </div>
        <table mat-table #sort="matSort" [dataSource]="dataSourceUltimasFacturas" matSort matSortActive="FechaFactura"
          matSortDirection="desc">
          <!-- Cliente -->
          <ng-container matColumnDef="Cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Cliente' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.Cliente}}</td>
          </ng-container>
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Entorno' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Fecha Factura -->
          <ng-container matColumnDef="FechaFactura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fecha Factura' | translate}}  </th>
            <td mat-cell *matCellDef="let element"> {{element.FechaFactura | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <!-- Numero Factura -->
          <ng-container matColumnDef="NumFactura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Número Factura' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="SimplificacionSerieFactura && isNumber(element.Serie)">{{element.NumFactura}}</ng-container>
                <ng-container *ngIf="!(SimplificacionSerieFactura && isNumber(element.Serie))">{{element.NumFactura}}</ng-container>
            </td>
          </ng-container>
          <!-- Importe total -->
          <ng-container matColumnDef="Importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Importe' | translate}}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-danger': element.Importe < 0}">
              {{element.Importe | currency:'EUR':'symbol':'':'es'}}</td>
          </ng-container>
           <!-- Descargar -->
           <!-- <ng-container matColumnDef="Descargar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Descargar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdContratoDocumento; else elseDescargarBlock">
                <button mat-button (click)="descargaFactura(element)">
                  <span class="fal fa-file-download fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseDescargarBlock>
                <button mat-button disabled>
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-template>
            </td>
          </ng-container> -->

          <!-- Descargar  No funciona, por qué?-->
          <ng-container matColumnDef="Descargar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Descargar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdDocumento; else elseDescargarBlock">
                <button mat-button (click)="descargaFactura(element)">
                  <span class="fal fa-file-download fa-lg"></span>
                </button>
              </ng-container>
              <ng-template #elseDescargarBlock>
                <button mat-button disabled>
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnasUltimasFacturas"></tr>
          <tr mat-row *matRowDef="let row; columns: columnasUltimasFacturas;"></tr>
        </table>
        <div *ngIf="textoUltimasFacturasAdminFincas"
          style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
          <span>{{'No hay datos disponibles en últimas facturas.' | translate}}</span>
        </div>
        <!-- <button *ngIf="MostrarBotonFacturasImpagadas && pendientespago > 0" mat-button color="primary"
          matTooltip="Mostrar sólo facturas impagadas" (click)="mostrarSoloFacturasImpagadas()"  style="font-size: 20px;"><i
            class="fas fa-file-exclamation"></i>
            <span *ngIf="!facturasImpagadas">{{'Mostrar impagadas' | translate}}</span>
            <span *ngIf="facturasImpagadas">{{'Mostrar todas' | translate}}</span>
        </button> -->
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
      </mat-card>
      <!-- Gráfico estados contratos ¿Y ésto qué es? -->
      <!-- <mat-card fxFlex="50" class="of p-0">
        <div class="p-10">
          <highcharts-chart [Highcharts]="Highcharts" [options]="graficoEstadosContratosAdminFincas" class="grafico">
          </highcharts-chart>
        </div>
      </mat-card> -->
    </div>
  </div>

  <!-- Administrador Fincas -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="TipoCliente === '3'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <!-- Últimos contratos -->
      <mat-card fxFlex class="of p-0" style="display: none;">
        <div class="container-title-table">
          <div class="table-title">{{'Últimos Contratos' | translate}}</div>
          <div class="table-subtitle">{{'No hay datos disponibles del último mes.' | translate}} {{fechaInicio | date: 'dd/MM/yyyy'}}
             {{'hasta' | translate}} {{fechaHoy | date: 'dd/MM/yyyy'}}</div>
          <mat-divider></mat-divider>
        </div>
        <table mat-table #sort2="matSort" [dataSource]="dataSourceUltimosContratos" matSort matSortActive="FechaAlta"
          matSortDirection="desc">
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Entorno' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Cliente -->
          <ng-container matColumnDef="Cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Cliente' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.Cliente}}</td>
          </ng-container>
          <!-- Fecha Alta -->
          <ng-container matColumnDef="FechaAlta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fecha Alta' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.FechaAlta | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <!-- Contrato -->
          <ng-container matColumnDef="CodigoContrato">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contrato' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <button mat-button color="primary" class="text-center" matTooltip="Ir al Contrato"
                [routerLink]="['/informacion', 'perfil', 'contrato', element.IdContrato]">{{element.CodigoContrato}}</button>
            </td>
          </ng-container>
          <!-- CUPS -->
          <ng-container matColumnDef="CodigoCUPS">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUPS </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button color="primary" class="text-center" matTooltip="Ir al CUPS"
                [routerLink]="['/informacion', 'perfil', 'cups', element.IdCups]">{{element.CodigoCUPS}}</button>
            </td>
          </ng-container>
          

          <tr mat-header-row *matHeaderRowDef="columnasUltimosContratos"></tr>
          <tr mat-row *matRowDef="let row; columns: columnasUltimosContratos;"></tr>
        </table>
        <div *ngIf="textoUltimasContratosAdminFincas"
          style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
          <span>{{'No hay datos disponibles en últimos contratos.' | translate}}</span>
        </div>
        <mat-paginator #paginator2="matPaginator" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>

  <!-- Lectura / Modificación / Solicitud -->
  <div fxLayoutAlign="center stretch" fxLayout.lt-md="column" *ngIf="TipoCliente === '1' || TipoCliente === '2'">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-md="column" fxLayoutGap="20px">
      <!-- Nueva lectura -->
      <mat-card fxFlex="33.3" [fxFlex.lt-md]="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <span class="{{ iconoresumennuevalectura }}" aria-hidden="true"></span>
        <span class="mat-h2 text-center">{{'Dar una nueva lectura de contador' | translate}}</span>
        <button mat-stroked-button (click)="nuevaLectura()" [disabled]="DisabledBotonNuevaLectura">{{'Lectura Nueva' | translate}}</button>
      </mat-card>
      <!-- Modificar contrato -->
      <mat-card fxFlex="33.3" [fxFlex.lt-md]="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="OcultarContactosEleia == false">
        <span class="{{ iconoresumendatospago }}" aria-hidden="true"></span>
        <span class="mat-h2 text-center">{{'Modifica tus datos de pago' | translate}}</span>
        <button mat-stroked-button (click)="modificar()" [disabled]="DisabledBotonModificarDatos">{{'Modifica tus datos de pago' | translate}}</button>
      </mat-card>
      <!-- Nueva solicitud -->
      <mat-card fxFlex="33.3" [fxFlex.lt-md]="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <span class="{{ iconoresumenconsulta }}" aria-hidden="true"></span>
        <span class="mat-h2 text-center">{{'Realiza una consulta o reclamación' | translate}}</span>
        <button mat-stroked-button [disabled]="DisabledBotonRealizarConsulta" (click)="consultar()">{{'Consulta' | translate}}</button>
      </mat-card>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { getTreeMultipleDefaultNodeDefsError } from '@angular/cdk/tree';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { FuncionesService } from 'src/app/services/funciones/funciones.service';
import { MailService } from 'src/app/services/mail/mail.service';
import { environment } from 'src/environments/environment';
import { Email } from '../../../../interfaces/Email';


@Component({
  selector: 'app-formulario-nueva-lectura',
  templateUrl: './formulario-nueva-lectura.component.html',
  styleUrls: ['./formulario-nueva-lectura.component.scss']
})
export class FormularioNuevaLecturaComponent implements OnInit {

  cups:any;


  @Input() eleccion: string;
  @Input() replyTo: string;

  cupsFiltered: string[];

  isDisable = true;
  form: FormGroup;

  DatosCups: any;

  p1: any;
  p2: any;
  p3: any;
  p4: any;
  p5: any;
  p6: any;
  caudal: any;

  constructor(
    private fb: FormBuilder,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _mailService: MailService
  ) {

    this.cupsFiltered = JSON.parse(localStorage.getItem('cupsFiltered'));

    this.p1 = false;
    this.p2 = false;
    this.p3 = false;
    this.p4 = false;
    this.p5 = false;
    this.p6 = false;
    this.caudal = false;

  }

  ngOnInit() {
    // this.eleccion = "luz";
    // console.log(this.eleccion);
    this.form = this.fb.group({
      selectedCups: new FormControl(),
      entorno: new FormControl(),
      p1: new FormControl(),
      p2: new FormControl(),
      p3: new FormControl(),
      p4: new FormControl(),
      p5: new FormControl(),
      p6: new FormControl(),
      caudal: new FormControl(),
      asunto: new FormControl(),
      cuerpo: new FormControl()
    });
  }

  BusquedaNuevoCups(idCups) {

    this._contratosService.getDatosCups(idCups).subscribe(
      async datosCupsMultipunto => {
        this.DatosCups = this._funcionesService.decodificarToken(datosCupsMultipunto);
        console.log(this.DatosCups);
        console.log(this.DatosCups.Tarifa);
        let tarifasinespacios = "";
        if (this.DatosCups)
          tarifasinespacios = this.DatosCups.Tarifa.replace(/\s/g, '');

        switch (tarifasinespacios) {
          case "2.0AML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.1AML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.0DHAML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.0DHSML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.1DHAML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.1DHSML":
            this.p1 = true;
            this.p2 = false;
            this.p3 = true;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "2.0TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "3.0AML":
            this.p1 = false;
            this.p2 = true;
            this.p3 = false;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "3.1AML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "3.1":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = false;
            this.p5 = false;
            this.p6 = false;
            this.caudal = false;
            break;
          case "3.0TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.0AML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.1BML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.1AML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.2ML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.3ML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = true;
            break;
          case "6.4ML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.5ML":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.0TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.1TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.2TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.3TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          case "6.4TD":
            this.p1 = true;
            this.p2 = true;
            this.p3 = true;
            this.p4 = true;
            this.p5 = true;
            this.p6 = true;
            this.caudal = false;
            break;
          default:
            // this.p1 = false;
            // this.p2 = false;
            // this.p3 = false;
            // this.p4 = false;
            // this.p5 = false;
            // this.p6 = false;
            // this.caudal = false;
            break;
        }

      });
  }

  onChangeValue() {
    if ((this.form.value.p1 || this.form.value.p2 || this.form.value.p3 || this.form.value.p4
      || this.form.value.p5 || this.form.value.p6 || this.form.value.caudal) &&
      (this.form.value.p1 > 0 || this.form.value.p2 > 0 || this.form.value.p3 > 0 || this.form.value.p4 > 0
        || this.form.value.p5 > 0 || this.form.value.p6 > 0 || this.form.value.caudal > 0)) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
  }

  onEnviarLectura() {

    let cuerpo = '';
    let replyTo = this.replyTo;


    if (localStorage.getItem('TipoCliente') == '3') {
      cuerpo = '<h3>Las lecturas facilitadas por el Administrador "'
        + localStorage.getItem('usuarioCambioPass') + '"  son las siguientes:</h3>';
    } else {
      cuerpo = '<h3>Las lecturas facilitadas por el Cliente "' + localStorage.getItem('usuarioCambioPass') + '" son las siguientes:</h3>';
    }

    (replyTo != "") ? cuerpo = '<p>El cliente con email: ' + replyTo + '  </p>' : null;
    cuerpo = cuerpo + '<p>CUPS: ' + this.DatosCups.CodigoCUPS + '</p>';
    if (this.form.value.p1) { cuerpo = cuerpo + '<p>P1: ' + this.form.value.p1 + '</p>'; }
    if (this.form.value.p2) { cuerpo = cuerpo + '<p>P2: ' + this.form.value.p2 + '</p>'; }
    if (this.form.value.p3) { cuerpo = cuerpo + '<p>P3: ' + this.form.value.p3 + '</p>'; }
    if (this.form.value.p4) { cuerpo = cuerpo + '<p>P4: ' + this.form.value.p4 + '</p>'; }
    if (this.form.value.p5) { cuerpo = cuerpo + '<p>P5: ' + this.form.value.p5 + '</p>'; }
    if (this.form.value.p6) { cuerpo = cuerpo + '<p>P6: ' + this.form.value.p6 + '</p>'; }
    if (this.form.value.caudal) { cuerpo = cuerpo + '<p>Caudal: ' + this.form.value.caudal + '</p>'; }

    let email: Email = { Asunto: 'Envío de formulario de nueva lectura', Destinatario: environment.email, Cuerpo: cuerpo, ReplyTo: replyTo };

    this._mailService
      .getEnvioEmail(email)
      .subscribe(async data => {
        // this.isLoading = false;
        console.log(data);
      });

  }
}

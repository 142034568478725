import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../config/global/global.service';
import { Token } from '../../interfaces/token';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  TipoCliente: any;
  token: any;
  usuariolog: string;
  passlog: string;
  datosTitular: any;
  urlApi: string;
  bodyApiToken: any;

  constructor(private http: HttpClient, private _globalService: GlobalService) {
    this.urlApi = this._globalService.getUrlApi();
    this.bodyApiToken = this._globalService.getUsuarioApi();
  }

  getToken(u, p): Observable<Token> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: httpHeaders };
    const body = '{"Usuario":"'+btoa(u)+'","Password":"'+btoa(p)+'"}';
    return this.http.post<Token>(this.urlApi + 'Login/Token', body, options);
  }

  getvalidaToken(u): Observable<Token> {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json',Authorization: this.token});
    const options = { headers: httpHeaders };
    const body = '{"IdCliente":"'+u+'"}';
    return this.http.post<Token>(this.urlApi + 'Login/ValidarIdCLiente', body, options);
  }

  getLogin(u, p): Observable<Token> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: this.token });
    const options = { headers: httpHeaders };
    const body = { usuario: u, password: p };
    return this.http.post<Token>(this.urlApi + 'Login/DatosTitular', body, options);
  }

  getValidation(u, p, t): Observable<Token> {
    this.token = t;
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: this.token });
    const options = { headers: httpHeaders };
    const body = { usuario: u, password: p };
    return this.http.post<Token>(this.urlApi + 'Login/Validation', body, options);
  }

  // Comprobamos si es la primera vez que se loguea
  // getPrimeraVez(idCliente): Observable<Token> {
  //   const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: this.token });
  //   const options = { headers: httpHeaders };
  //   const body = { IdCliente: idCliente };
  //   return this.http.post<Token>(this.urlApi + 'Modificacion/CambioPassword', body, options);
  // }

  getLoginRefresco(u, p, t): Observable<any> {
    // Si no existe el token, lo vuelvo a pedir, por si le dan al F5
    this.usuariolog = u;
    this.passlog = p;
    this.token = t;

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders
    };
    const body = { usuario: u, Password: p };

    return this.http.post<any>(
      this.urlApi + 'Login/DatosTitular',
      body,
      options
    );
  }
}

<div mat-dialog-content [formGroup]="form">
    <h2>Introduzca la nueva lectura</h2>
    <div>
      <mat-form-field class="input-full-width">
        <mat-select placeholder="CUPS" formControlName="selectedCups" [(ngModel)]="cups" (selectionChange)="BusquedaNuevoCups($event.value)">
          <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{ Cups.CodigoCups }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <mat-form-field>
        <mat-select placeholder="CUPS" (selectionChange)="BusquedaNuevoCups($event.value)" [formControl]="selectedCups"
          [(ngModel)]="cups">
          <input class="myInput" #myInput focused="'true'" type="text" (keyup)="search($event.target.value)"
            autocomplete="off" placeholder="Buscar Cups">
          <mat-divider></mat-divider>
          <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{ Cups.CodigoCups }}</mat-option>
        </mat-select>
      </mat-form-field> -->

    <mat-form-field class="full-width-field" *ngIf="p1 && eleccion == 'luz'" class="input-full-width">
      <input matInput placeholder="P1" value="" formControlName="p1" (keyup)="onChangeValue()" type="number">
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="p2 && eleccion == 'luz'" class="input-full-width">
      <input matInput placeholder="P2" value="" formControlName="p2" (keyup)="onChangeValue()" type="number">
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="p3 && eleccion == 'luz'" class="input-full-width">
      <input matInput placeholder="P3" value="" formControlName="p3" (keyup)="onChangeValue()" type="number">
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="p4 && eleccion == 'luz'" class="input-full-width">
      <input matInput placeholder="P4" value="" formControlName="p4" (keyup)="onChangeValue()" type="number">
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="p5 && eleccion == 'luz'" class="input-full-width">
      <input matInput placeholder="P5" value="" formControlName="p5" (keyup)="onChangeValue()" type="number">
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="p6 && eleccion == 'luz'" class="input-full-width" type="number">
        <input matInput placeholder="P6" value="" formControlName="p6" (keyup)="onChangeValue()" type="number">
      </mat-form-field>

    <div>
    <mat-form-field class="full-width-field" *ngIf="eleccion == 'gas'" class="input-full-width">
      <input matInput placeholder="Caudal" value="" formControlName="caudal" (keyup)="onChangeValue()" type="number">
    </mat-form-field>
    <div>

</div>

<div mat-dialog-actions align="center">
    <!-- <button mat-button (click)="test()">No Thanks</button> -->
    <button mat-button class="input-full-width" (click)="onEnviarLectura()" [mat-dialog-close] cdkFocusInitial [disabled]="isDisable"><i class="fal fa-vote-yea" matTooltip="Enviar lectura"></i>{{'Enviar nueva lectura' | translate}}</button>
  </div>
  <br><br>
import { Component, OnInit,  ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FacturasMasivasDialogComponent } from '../../dialogs/facturas-masivas-dialog/facturas-masivas-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { LoginService } from '../../../services/login/login.service';
import { Contrato } from '../../../interfaces/contrato';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { SpinnerDialogComponent } from '../../../components/dialogs/spinner-dialog/spinner-dialog.component';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
    
    //Declaración de variables.
    dialogRef: any;
    dataSource = new MatTableDataSource<Contrato>();
    MostrarDescargaMasivaFacturasPorCups: any;
    visibleFacturas:any;
    displayedColumns:any;
    tokenvalidado: any;
    ApiCallContratosListaElekluz:any;
    isLoading = true;
    contratos: any;
    mostrarSpinner = true;
    textoModal: string;
    msgError = "";
    monopunto:boolean;
    color = 'primary';
    mode = 'indeterminate';
    value = 50;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<Contrato>(true, []);

    EntornoFilter = new FormControl();
    NumFincaFilter = new FormControl();
    ContratoFilter = new FormControl();
    SituacionFilter = new FormControl();
    IdentidadFilter = new FormControl();
    DireccionSuministroFilter = new FormControl();
    DenominacionClienteFilter = new FormControl();
    CupsFilter = new FormControl();
    FechaAltaFilter = new FormControl();
    FechaVencimientoFilter = new FormControl();
    FechaBajaFilter = new FormControl();
    // TarifaFilter = new FormControl();
    DeudaFilter = new FormControl();

  filteredValues = {
    Entorno: '',
    NumFinca: '',
    Contrato: '',
    Situacion: '',
    Identidad: '',
    DireccionSuministro: '',
    DenominacionCliente: '',
    Cups: '',
    FechaAlta: '',
    FechaVencimiento: '',
    FechaBaja: '',
  };
    
  constructor(
      private router: Router,
     private _contratosService: ContratosService,
      private _funcionesService: FuncionesService,
      private _loginService: LoginService,
      public dialog: MatDialog) {
  }

  ngOnInit() {
    this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
      data => {
        this.tokenvalidado = this._funcionesService.decodificarToken(data);
        if(this.tokenvalidado.Valido == true){
           if(this.ApiCallContratosListaElekluz && localStorage.getItem('isAdminFincas') === 'true'){
             // console.log(this.tipocliente);
            this._contratosService
              .getListadoContratosElekluz(localStorage.getItem('idCliente'))
              .subscribe(async data => {
                this.isLoading = false;
                this.contratos = this._funcionesService.decodificarToken(data);
                // console.log(this.contratos);
                this.monopunto = (this.contratos.length === 1) ? false : false;
                this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.mostrarSpinner = false;
                this.customFilter();
              },
                err => {
                  this.isLoading = false;
                  this.textoModal = this._funcionesService.decodificarToken(
                    err.error
                  )['0'].Error;
                  this.mostrarSpinner = false;
                  this.dialog.open(LoginDialogComponent, {
                    width: '400px',
                    data: {
                      texto: this.textoModal,
                      titulo: 'Error en contratos'
                    }
                  });
                }
              );

          }else{
            this._contratosService
              .getListadoContratos(localStorage.getItem('idCliente'))
              .subscribe(async data => {
                this.isLoading = false;
                this.contratos = this._funcionesService.decodificarToken(data);
                // console.log(this.contratos);
                this.monopunto = (this.contratos.length === 1) ? false : false;
                this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.mostrarSpinner = false;
                this.customFilter();
              },
                err => {
                  this.isLoading = false;
                  this.textoModal = this._funcionesService.decodificarToken(
                    err.error
                  )['0'].Error;
                  this.mostrarSpinner = false;
                  this.dialog.open(LoginDialogComponent, {
                    width: '400px',
                    data: {
                      texto: this.textoModal,
                      titulo: 'Error en contratos'
                    }
                  });
                }
              );

            };
          }else{
            this.router.navigate(['/salir']);
          }});

    this.ApiCallContratosListaElekluz = environment.ApiCallContratosListaElekluz;

    this.MostrarDescargaMasivaFacturasPorCups = environment.MostrarDescargaMasivaFacturasPorCups;
    if (localStorage.getItem('isAdminFincas') === 'true') {
      this.visibleFacturas = true;
    } else {
      this.visibleFacturas = false;
      this.displayedColumns = this.displayedColumns.filter(function (col) {
        return col.localeCompare('NumFinca'); // Quitar columna NumFinca
      });
    }

    
}


        //Funcionalidad del botón de descarga masiva de facturas.
    descargaMasivaFacturasDialog() {


      
      // if(this.contratos==undefined){
      //   this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
      //     data:{
      //       'IsAdministrador': localStorage.getItem('isAdminFincas'),
      //       'IdCliente': localStorage.getItem('idCliente'),
      //       'Entorno': localStorage.getItem('entorno'),
      //       'ListadoContratos': this.dataSource.data
      //     }
      //   });
      // }else{
        this.dialogRef = this.dialog.open(FacturasMasivasDialogComponent, {
          data:{
            'IsAdministrador': localStorage.getItem('isAdminFincas'),
            'IdCliente': localStorage.getItem('idCliente'),
            'Entorno': localStorage.getItem('entorno'),
            'ListadoContratos': this.dataSource.data
          }
        });

    //   while (this.contratos==undefined){
    //   this.dialogRef = this.dialog.open(SpinnerDialogComponent, {
    //     data:{
    //       'IsAdministrador': localStorage.getItem('isAdminFincas'),
    //       'IdCliente': localStorage.getItem('idCliente'),
    //       'Entorno': localStorage.getItem('entorno'),
    //       'ListadoContratos': this.dataSource.data
    //     }
    //   });
    // }
    // this.dialogRef = this.dialog.open(FacturasMasivasDialogComponent, {
    //   data:{
    //     'IsAdministrador': localStorage.getItem('isAdminFincas'),
    //     'IdCliente': localStorage.getItem('idCliente'),
    //     'Entorno': localStorage.getItem('entorno'),
    //     'ListadoContratos': this.dataSource.data
    //   }
    // });
      // }
  }


    customFilter() {
      this.EntornoFilter.valueChanges.subscribe(EntornoFilterValue => {
        this.filteredValues['Entorno'] = EntornoFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.NumFincaFilter.valueChanges.subscribe(NumFincaFilterValue => {
        this.filteredValues['NumFinca'] = NumFincaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.ContratoFilter.valueChanges.subscribe(ContratoFilterValue => {
        this.filteredValues['Contrato'] = ContratoFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.SituacionFilter.valueChanges.subscribe(SituacionFilterValue => {
        this.filteredValues['Situacion'] = SituacionFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.IdentidadFilter.valueChanges.subscribe(IdentidadFilterValue => {
        this.filteredValues['Identidad'] = IdentidadFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.DireccionSuministroFilter.valueChanges.subscribe(DireccionSuministroFilterValue => {
        this.filteredValues['DireccionSuministro'] = DireccionSuministroFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.DenominacionClienteFilter.valueChanges.subscribe(DenominacionClienteFilterValue => {
        this.filteredValues['DenominacionCliente'] = DenominacionClienteFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.CupsFilter.valueChanges.subscribe(CupsFilterValue => {
        this.filteredValues['Cups'] = CupsFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.FechaAltaFilter.valueChanges.subscribe(FechaAltaFilterValue => {
        this.filteredValues['FechaAlta'] = FechaAltaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.FechaVencimientoFilter.valueChanges.subscribe(FechaVencimientoFilterValue => {
        this.filteredValues['FechaVencimiento'] = FechaVencimientoFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.FechaBajaFilter.valueChanges.subscribe(FechaBajaFilterValue => {
        this.filteredValues['FechaBaja'] = FechaBajaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      // this.TarifaFilter.valueChanges.subscribe(TarifaFilterValue => {
      //   this.filteredValues['Tarifa'] = TarifaFilterValue;
      //   this.dataSource.filter = JSON.stringify(this.filteredValues);
      // });
      this.DeudaFilter.valueChanges.subscribe(DeudaFilterValue => {
        this.filteredValues['Deuda'] = DeudaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    
  customFilterPredicate() {
    const myFilterPredicate = function (data: Contrato, filter: string): boolean {

      const searchString = JSON.parse(filter);
      if(data.DenominacionCliente == null){
        data.DenominacionCliente = '';
      }
      if(data.FechaBaja == null){
        data.FechaBaja = '';
      }
      return (
        data.Entorno.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Entorno.toLowerCase()) !== -1 &&
        data.NumFinca.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.NumFinca.toLowerCase()) !== -1 &&
        data.CodigoContrato.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Contrato.toLowerCase()) !== -1 &&
        data.ContratoSituacion.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Situacion.toLowerCase()) !== -1 &&
        data.Identidad.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Identidad.toLowerCase()) !== -1 &&
        data.DireccionSuministro.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DireccionSuministro.toLowerCase()) !== -1 &&
        data.DenominacionCliente.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DenominacionCliente.toLowerCase()) !== -1 &&
        data.Cups.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Cups.toLowerCase()) !== -1 &&
        data.FechaAlta.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaAlta.toLowerCase()) !== -1 &&
        data.FechaVto.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaVencimiento.toLowerCase()) !== -1 &&
        data.FechaBaja.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaBaja.toLowerCase()) !== -1
        // data.Tarifa.toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Tarifa.toLowerCase()) !== -1
      );
    };
    return myFilterPredicate;
  }
    
    

       /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

   /** Clear selection. */
   clear() {
     this.selection.clear()
 }
    }

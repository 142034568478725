import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { LoginService } from '../../services/login/login.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {
  // idFacturaVentaCabecera: any;
  token: string;
  urlApi: string;
  bodyApiToken: any;
  listadoFacturas: any; // TODO: Pasar a Factura[]
  ultimasFacturas: any; // TODO
  agrupacontratoscups = environment.agrupacontratoscups;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService) {
    // Direccion url API
    this.urlApi = this._globalService.getUrlApi();
    this.token = localStorage.getItem('token');
    
  }
  refreshtoken(){
    this.token = localStorage.getItem('token');
  }
  getListadoFacturas(codigo, fechaInicio?, fechaFinal?, isResumen?, cups?, identidad?,Ultimasfacturas?): Observable<any> {
    this.refreshtoken();
    /**
     * Cuando es admin fincas y multipunto codigo = codigoContrato
     * Cuando es monopunto, codigo = idcliente
     */
    let body = {};
     if(this.agrupacontratoscups && !isResumen){
        body = { CUPS: cups, Identidad: identidad, FechaInicio: fechaInicio, FechaFin: fechaFinal };
        return this.http.post<any>(this.urlApi + 'Facturas/GetFacturasAgrupadasListaComprobarCliente', body, this._globalService.getHttpHeaders());
      }else{
        (isResumen === undefined || isResumen === '') ? isResumen = false : isResumen = true;
        if ((localStorage.getItem('TipoCliente') === '3' || localStorage.getItem('TipoCliente') === '2') && !isResumen) {
          body = { Codigo: codigo, IsCliente: false, FechaInicio: fechaInicio, FechaFin: fechaFinal, UltimasFacturas: Ultimasfacturas };
        } else {
          body = { Codigo: codigo, IsCliente: true, FechaInicio: fechaInicio, FechaFin: fechaFinal, UltimasFacturas: Ultimasfacturas };
        }
        return this.http.post<any>(this.urlApi + 'Facturas/GetFacturasListaComprobarCliente', body, this._globalService.getHttpHeaders());
      }
  }

  getDescargaFactura(idContratoDocumento): Observable<Blob> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdContratoDocumento: idContratoDocumento };
    return this.http.post<any>(
      this.urlApi + 'Facturas/GetFacturaPDFCompruebaCliente',
      body,
      options
    );
  }

  getDescargaPrecios(idContratoDocumento): Observable<Blob> {
    this.refreshtoken()
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdContratoDocumento: idContratoDocumento };
    return this.http.post<any>(
      this.urlApi + 'Facturas/GetFacturaPreciosXLSX',
      body,
      options
    );
  }

  getDescargaFacturasZip(array): Observable<Blob> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { 'Ids': array };

    return this.http.post<any>(
      this.urlApi + 'Facturas/FacturasDownloadComprobarCliente',
      body,
      options
    );
  }

  getDesgloseFacturasExcel(idfacturas, isatr): Observable<any> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdsFacturas: idfacturas, IsATR: isatr };
    if(environment.companyName=='Conecta2 Energía'){
      // console.log('Entra');
      return this.http.post<any>(this.urlApi + 'Facturas/DescargaExcelFacturasDesgloseComprobarClienteConecta2', body, options);
    }else{
      // console.log('No entra' );
      return this.http.post<any>(this.urlApi + 'Facturas/DescargaExcelFacturasDesgloseComprobarClienteV2', body, options); 
    }
  }

  getUltimasFacturas(idAdministrador, fechaInicio, fechaFin): Observable<any> {
    const body = { IdAdministrador: idAdministrador, FechaInicio: fechaInicio, FechaFin: fechaFin };
    return this.http.post<any>(this.urlApi + 'Resumen/UltimasFacturas', body, this._globalService.getHttpHeaders());
  }

  getImportesBianual(idCups, fechaInicio, fechaFinal,idcliente,idcontrato): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal,IdCliente:idcliente,IdContrato: idcontrato};
    return this.http.post<any>(this.urlApi + 'Resumen/GraficoBianualImportes', body, this._globalService.getHttpHeaders());
  }

  getImportes(idCups, fechaInicio, fechaFinal,idcliente,idcontrato): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal,IdCliente:idcliente,IdContrato: idcontrato };
    return this.http.post<any>(this.urlApi + 'Resumen/Importes', body, this._globalService.getHttpHeaders());
  }

  getConsumosBianual(idCups, fechaInicio, fechaFinal,idcliente,idcontrato): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal,IdCliente:idcliente,IdContrato: idcontrato };
    return this.http.post<any>(this.urlApi + 'Resumen/GraficoBianualConsumos', body, this._globalService.getHttpHeaders());
  }

  getConsumos(idCups, fechaInicio, fechaFinal,idcliente,idcontrato): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal,IdCliente:idcliente,IdContrato: idcontrato };
    return this.http.post<any>(this.urlApi + 'Resumen/Consumos', body, this._globalService.getHttpHeaders());
  }

  getIdFacturasByCups(id, isAdministrador, fechaInicio, fechaFin, listadoCups,
    cif,importe,selectedtarifa): Observable<any> {
    const body = { 
      IdCliente: id, 
      IsAdministrador: isAdministrador, 
      FechaInicio: fechaInicio, 
      FechaFin: fechaFin, 
      CUPS: listadoCups,
      Cif: cif,
      Importe: importe,
      Tarifa: selectedtarifa

    };
    if(environment.companyName=='Conecta2 Energía'){
      // console.log('Entra');
      return this.http.post<any>(this.urlApi + 'Facturas/GetIdFacturasListaConecta2', body, this._globalService.getHttpHeaders());
    }else{
      // console.log('No entra' );
      return this.http.post<any>(this.urlApi + 'Facturas/GetIdFacturasLista', body, this._globalService.getHttpHeaders());
    }
   
  }


  postPagoTpvRedsys(tarjetaNumero, tarjetaNombre, tarjetaMes, tarjetaAno, tarjetaCcv, importeTotal, numeroFactura): Observable<any> {


    const timeStamp = Math.floor(Date.now());
    const body = {
      "DS_MERCHANT_AMOUNT": importeTotal,
      "DS_MERCHANT_ORDER": numeroFactura,
      "DS_MERCHANT_MERCHANTCODE": `${numeroFactura} - ${timeStamp}`,
      "DS_MERCHANT_CURRENCY":"978",
      "DS_MERCHANT_TRANSACTIONTYPE":"0",
      "DS_MERCHANT_TERMINAL":"1",
      "DS_MERCHANT_MERCHANTURL":"http:\/\/www.prueba.com\/urlNotificacion.php",
      "DS_MERCHANT_URLOK":"http:\/\/www.prueba.com\/urlOK.php",
      "DS_MERCHANT_URLKO":"http:\/\/www.prueba.com\/urlKO.php",
      "DS_MERCHANT_PAN": tarjetaNumero,
      "DS_MERCHANT_EXPIRYDATE":`${tarjetaAno}${tarjetaMes}`,
      "DS_MERCHANT_CVV2":`${tarjetaCcv}`
    };
    return this.http.post<any>(this.urlApi + 'TpvRedsys/Pago', body, this._globalService.getHttpHeaders());
  }
  
}

<head>
    <meta http-equiv="Expires" content="0">
    <meta http-equiv="Last-Modified" content="0">
    <meta http-equiv="Cache-Control" content="no-cache, mustrevalidate">
    <meta http-equiv="Pragma" content="no-cache">
    <script src="myfile.js?version=1.0.1"></script>
</head>
<app-header></app-header>
<div class="wrapper">
    <div class="container"  fxLayoutAlign="center stretch" fxLayout.lt-lg="column" >

        <!-- Éste nuevo componente lo hemos diseñado expresamente para el nuevo desarrollo de Elekluz
         siguiendo sus indicaciones iniciales para el mismo.
            Está constituido por un panel con diferentes iconos, cada uno de los cuales enlaza a su vez con un componente
        o sección de la web,  algunos preciamente existentes otros diseñados para tal fin
          -->
        <div class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <!-- Éste icono conduce a la sección de perfil previamente existente sin apenas cambios -->
            <p>
                {{'PERFIL'| translate}}
            </p>
            <button mat-button routerLinkActive="active" [routerLink]="['/perfil']" target="_blank">
                <img class="imagen" src="assets\img\Perfil.svg">
            </button>
        </div>
        <!--  -->

        <!-- <br> -->

        <!-- Éste icono conduce a la sección de resumen que antes servía de página inicial.
            En dicha sección se han eliminado los apartados de últimos contratos y facturas impagadas, quedando únicamente
            las últimas facturas de los contratos que ademnás quieren que se puedan descargar 
            (esta funcionalidad aún no ha sido  desarrollada e implementada con éxito) -->
        <div class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <p>
                {{'ÚLTIMAS FACTURAS'| translate}}
            </p>
            <button mat-button routerLinkActive="active"  target="_blank" [routerLink]="['/resumen']">
                <img class="imagen" src="assets\img\UltimaFactura.svg">
            </button>
        </div>
        <!-- </mat-card> -->

        <!-- <br> -->

        <!-- Éste icono conduce al listado de Contratos sin apenas cambios -->
        <div class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <p>
                {{'PUNTOS DE SUMINISTRO'| translate}}
            </p>
            <button mat-button routerLinkActive="active" target="_blank" [routerLink]="['/informacion','contratos']">
                <img class="imagen" src="assets\img\PuntoSuministro.svg">
            </button>
        </div>
        <!-- </mat-card> -->

        <!-- <br> -->
    
    </div>
    
    <div class="back" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" >
         <!-- La idea de este icono es que desde él puedan descargarse masivamente las facturas con la misma funcionalidad
            que existe en el listado de contratos. Dicha funcionalidad aún no estñá funcional -->
           
        <div class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <p >
                {{'DESCARGA MASIVA'| translate}}
            </p>
            <button *ngIf="!isLoading" mat-button (click)="descargaMasivaFacturasDialog()">
                <img class="imagen" src="assets\img\DescargaMasiva.svg">
            </button>
            <div  *ngIf="isLoading">
                <h2 mat-dialog-title class="ml-80">{{'Cargando' | translate}} ...</h2>
        
                    <mat-dialog-content>
                    <mat-progress-spinner class="ml-80" [color]="color" [mode]="mode" [value]="value">
                    </mat-progress-spinner>
                    </mat-dialog-content>
            </div>
        </div>
        
        <!-- </mat-card> -->

        <!-- <br> -->
          <!-- Este icono conduce a una nueva sección en la que el agente dispondrá de información de interés
                para desarrollar su actividad de la mejor forma posible. -->
        <div  class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <p>
                {{'INFORMACIÓN DE INTERÉS'| translate}}
            </p>
            <button mat-button routerLinkActive="active" target="_blank" [routerLink]="['/informacion','informacion']">
                <img class="imagen" src="assets\img\Informacion.svg">
            </button>
        </div>
        
        <br>

        <!-- La idea de este icono es que nos lleve a un listado similar al de contratos,
            pero con menos información donde el administrador de fincas pueda
            solicitar que se modifique información de un punto de suministro con la funcionalidad
            que ya existía en el apartado de perfil> -->

        <div class="menu" fxFlex="33.3" [fxFlex.lt-md]="100">
            <p>
                {{'MODIFICACIONES'| translate}}
            </p>
            <button mat-button routerLinkActive="active" target="_blank" [routerLink]="['/informacion','suministro']" >
                <img class="imagen" src="assets\img\Modificaciones.svg">
            </button>
        </div>
        <!-- </mat-card> -->
    </div>
</div>
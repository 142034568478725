
  <div *ngIf="nombreEmpresa!='Catgas Energia'" class="contenedor-idiomas">
    <span [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')"><img class="imagen" src="assets/img/flag-es.png" title="ES" alt="ES" /></span>
    <span  [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')"><img class="imagen" src="assets/img/flag-en.png" title="EN" alt="EN" /></span>
    <span  [ngClass]="{'active': activeLang == 'cat'}" (click)="cambiarLenguaje('cat')"><img class="imagen" src="assets/img/flag-cat.png" title="VAL" alt="VAL" /></span>
    <span  [ngClass]="{'active': activeLang == 'gal'}" (click)="cambiarLenguaje('gal')"><img class="imagen" src="assets/img/flag-gal.png" title="GAL" alt="GAL" /></span>
    <span  [ngClass]="{'active': activeLang == 'eus'}" (click)="cambiarLenguaje('eus')"><img class="imagen" src="assets/img/flag-eus.png" title="EUS" alt="EUS" /></span>
  </div>
  <div *ngIf="nombreEmpresa=='Catgas Energia'" class="contenedor-idiomas">
    <span  [ngClass]="{'active': activeLang == 'cat'}" (click)="cambiarLenguaje('cat')"><img class="imagen" src="assets/img/flag-cat.png" title="VAL" alt="VAL" /></span>
    <span [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')"><img class="imagen" src="assets/img/flag-es.png" title="ES" alt="ES" /></span>
    <span  [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')"><img  class="imagen" src="assets/img/flag-en.png" title="EN" alt="EN" /></span>
    <span  [ngClass]="{'active': activeLang == 'gal'}" (click)="cambiarLenguaje('gal')"><img class="imagen" src="assets/img/flag-gal.png" title="GAL" alt="GAL" /></span>
    <span  [ngClass]="{'active': activeLang == 'eus'}" (click)="cambiarLenguaje('eus')"><img class="imagen" src="assets/img/flag-eus.png" title="EUS" alt="EUS" /></span>
  </div>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  public activeLang = environment.defaultLang;
  nombreEmpresa : any;
  constructor(
    private translate: TranslateService
  ) {

  }
  ngOnInit() {
    this.nombreEmpresa=environment.companyName;
    if(localStorage.getItem('idioma')) {
      this.translate.use(localStorage.getItem('idioma'));
     this.translate.setDefaultLang(this.activeLang);
    }else{
      this.translate.use(this.activeLang); // idioma por defecto en el config del enviroment de empresa
    }
  }
  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
    localStorage.setItem('idioma', lang);
    var location = window.location.href;
    if(location.includes('/resumen')){
      window.location.href = location;
    }
  }
}
